"use client";
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useRealtimeDatabase } from '../../hooks/useRealtimeDatabase';
import { QuestionTypes } from '../../hooks/quiz/interface';
import { GameConfigResponse } from '../../utils/gameConfig/config.interface';
import { TurnContext } from '../../context/TurnContext';
import { useAppSelector } from '../../store/store';
import { getGameConfig } from '../../utils/gameConfig/getGameConfig';
import { getQuestions } from '../../hooks/quiz/getQuestions';
import { useTimer } from '../../hooks/useTimer';
import Player from '../../components/Player';
import Question from '../../components/Question';
import ControlBar from '../../components/ControlBar';
import ResultsPopup from '../../components/ResultsPopup';
import LottieConfetti from '../../components/Confetti';
import AmazonPopup from '../../components/AmazonPopup';
import { useLocation } from 'react-router-dom';
import MeetingComponent from "../../components/MeetingComponent";
import { message } from "antd";
import { useFlashMessage } from "../../context/FlashMessageContext";
import { updateSarathiStatus } from "../../utils/axiosHelper";
import { useLazyFetchGamesDetailsQuery } from "../../store/api/fetchGameDetail.slice";
import { Howl, Howler } from 'howler';
import { generateRandomFourDigitNumber } from "../../utils/apiHelper";


const initialState: State = {
  player1Score: 0,
  player2Score: 0,
  selectedOption: null,
  showConfetti: false,
  optionSelected: false,
  currentQuestionIndex: 0,
  shouldResetTimer: false
};

const Quiz: React.FC = () => {
  const { data, updateData } = useRealtimeDatabase('data');
  // const router = useRouter();

  const location = useLocation();

  // Function to parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const gameUuid = searchParams.get('uuid');
  const userType = searchParams.get('type');
  const userName = searchParams.get('userName');
  const expertId = searchParams.get('expertId');
  const category = searchParams.get('category');
  const level = searchParams.get('level');
  
  const [fetchGameData, { data: gameDetailData, isFetching, isLoading }] = useLazyFetchGamesDetailsQuery();

  //@ts-ignore
  let gameLevel: number = searchParams.get('level') ? parseInt(searchParams.get('level')) : 1;
  const { showMessage } = useFlashMessage();
  const [showPopup, setShowPopup] = useState(false);
  const [questionsList, setQuestionsList] = useState<QuestionTypes[]>();
  const [quizConfig, setQuizConfig] = useState<GameConfigResponse>();
  const [isCorrectAnswerShowed, setIsCorrectAnswerShowed] = useState(false);
  const { handleTurnChange, isUsersTurn } = useContext(TurnContext);
  const sarthiInfo = useAppSelector(data => data.sarathiSlice);
  const [answerText, setAnswerText] = useState("Correct Answer ")
  useEffect(() => {
    //@ts-ignore
    if (gameUuid) {
      fetchGameData(gameUuid)
    }
  }, [gameUuid])

  const reducer = (state: State, action: Action) => {
    switch (action.type) {
      case "SELECT_OPTION":
        return {
          ...state,
          selectedOption: action.payload.selectedOption,
          showConfetti: action.payload.selectedOption === action.payload.correctAnswer,
          optionSelected: true,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const { player1Score, player2Score, selectedOption, showConfetti } = state;
  //@ts-ignore
  const firebaseGameData = data?.[gameUuid][gameLevel];
  //@ts-ignore
  const currentQuestionIndex = firebaseGameData?.currentQuestionIndex || 0;


  const increaseQuestionNumber = () => {
    if (gameUuid) {
      // updateData(gameUuid.toString(), { [gameLevel]: { currentQuestionIndex: currentQuestionIndex + 1, sarathiName: sarthiInfo?.name, userName: userName } });
    }
  };



  const player1 = useMemo(() => ({
    name: gameDetailData?.userId?.name || '',
    score: player1Score,
    avatar: userName ? userName[0].toUpperCase() : "P",
  }), [player1Score,gameDetailData]);

  const player2 = useMemo(() => ({
    name: gameDetailData?.sarathiID?.name || '',
    score: player2Score,
    avatar: gameDetailData?.sarathiID?.profile || ''
  }), [player2Score, gameDetailData]);


  const playClapSound = async (isCorrect: boolean) => {
    // Reduce the volume of currently playing sounds
  Howler.volume(0.2); // Lower the volume to 20%

  const sound = new Howl({
    src: isCorrect ? '/sounds/clap.mp3' : '/sounds/wrong-answer.mp3',
    autoplay: true,
    volume: 1.0, // Full volume for the new sound
    onend: () => {
      console.log('Sound played successfully.');
      Howler.volume(1.0); // Restore volume after the sound ends
    },
  });

  sound.play();
  
  setTimeout(() => {
    sound.stop();
    Howler.volume(1.0); // Restore volume immediately after stopping the sound
  }, 2000);

  };



  const handleSelectOption = (selectedOption: string, correctAnswer: string) => {
    
  
    // debugger
    if (gameUuid && gameLevel) {
      const userScore = data[gameUuid][gameLevel]?.userScore || 0;
      const sarathiScore = data[gameUuid][gameLevel]?.sarathiScore || 0;

      let newUserScore = userScore;
      let newSarathiScore = sarathiScore;

      const isUserAnswered = (currentQuestionIndex + 1) % 2 === 1; // User answers on odd questions

      if (selectedOption === correctAnswer) {
        playClapSound(true);
        if (isUserAnswered) {
          newUserScore = newUserScore + 5;
          message.destroy();
          message.success(` 🎉Hooray🎉   +5`, 1)

        } else {
          newSarathiScore += 5;
          message.destroy();
          message.success(` 🎉Hooray🎉  +5`, 1)
        }
      }
      else {
        playClapSound(false);
        // message.info(`Correct Answer - ${correctAnswer}`, 1)
      }

      setTimeout(() => {
        updateData(gameUuid.toString(), {
          [gameLevel]: {
            correctAnswer: correctAnswer,
            userScore: newUserScore,
            sarathiScore: newSarathiScore,
            currentQuestionIndex: currentQuestionIndex + 1
          }
        });

        increaseQuestionNumber();
        dispatch({ type: "SELECT_OPTION", payload: { selectedOption, correctAnswer } });
      }, 1500)

      setIsCorrectAnswerShowed(true);

    }
  };

  const getQuizConfig = useCallback(async () => {
    const configData = await getGameConfig("QUIZ");
    setQuizConfig(configData);
  }, []);

  const getQuestionsList = useCallback(async (userId: string) => {
    //@ts-ignore
    if (userType === 'user' &&( questionsList?.length < 0  || !questionsList)) {
      //@ts-ignore
      const data = await getQuestions(gameLevel ? gameLevel : 1, userId, category || 'gk');
      if (gameUuid && data?.data?.output_details?.quiz_questions) {
        setQuestionsList(data?.data?.output_details?.quiz_questions);
        updateData(gameUuid, { question: data?.data?.output_details?.quiz_questions })
      }
    } else {
      if (gameUuid && gameLevel) {

        try {
          let questionList = data[gameUuid]['question']

          if (questionList.length > 0) setQuestionsList(questionList);
          else setQuestionsList([]);
        } catch (error) {

        }

      }

    }
  }, [data]);

  useEffect(() => {
    getQuizConfig();
  }, [getQuizConfig]);

  useEffect(() => {
    if (gameDetailData?.userId?._id) {
      getQuestionsList(gameDetailData?.userId?._id);
    }

  }, [getQuestionsList, gameDetailData?.userId?._id, level]);



  const totalQuestions = useMemo(() => questionsList?.length || 0, [questionsList]);
  const time = useTimer(1200, () => increaseQuestionNumber(), state.optionSelected);

  useEffect(() => {
    if (expertId && userType == 'sarathi') updateSarathiStatus(expertId, true)
    return () => {
      if (expertId) updateSarathiStatus(expertId, false)
    }
  }, [])

  useEffect(() => {
    if (firebaseGameData?.currentQuestionIndex === currentQuestionIndex && firebaseGameData?.correctAnswer) {
      showMessage(`Correct Answer -${capitalizeFirstLetter(firebaseGameData.correctAnswer)}`, 2000)
      setIsCorrectAnswerShowed(false);
    }
  }, [firebaseGameData?.answered])
  return (
    <div className={`flex flex-col h-screen pr-2 pl-2 justify-start sm:justify-center overflow-hidden transition-bg duration-500  items-center }`}>
      {questionsList?.length === 0 && <div className="flex flex-1 justify-center items-center">
        <div className="flex flex-col items-center text-center p-4 bg-white rounded-lg shadow-md">
          <span className="text-lg font-normalFont mb-2 inline-block">
            Awesome! You have completed the quiz. Please try again later. Still you can talk to
          </span>
          <span className="text-lg font-normal inline-block  text-teal-600">
            {gameDetailData?.sarathiID?.name}
          </span>
        </div>
        </div>}
      {expertId && gameDetailData && <MeetingComponent GameData={gameDetailData} sarathiId={expertId.toString()} />}
      {currentQuestionIndex < totalQuestions ? (
        <div className="flex flex-col w-full gap-2 sm:w-[60%]  max-w-screen-sm  overflow-hidden rounded-lg p-0 sm:p-6 mx-auto h-full">
          <audio id='local-player' muted />
          <div className="mt-4 border-[1px] border-gray-100 p-1 rounded-md shadow-sm">
         
            {gameDetailData?.userId && gameDetailData?.sarathiID && <Player player1={player1} player2={player2} currentQuestionNumber={currentQuestionIndex + 1} time={time} Time={1200} />}
          </div>
          <p className={`text-center rounded-sm mr-3 ml-3 mt-3 border-[0.1px] border-black  p-2  text-2xl ${((currentQuestionIndex + 1) % 2 !== 0) ? 'bg-lightLavender' : 'bg-amberLight'}`}>
            {((currentQuestionIndex + 1) % 2 != 0) ? "User's turn" : "Sarathi's Turn"}
          </p>
          <Question
            key={currentQuestionIndex}
            image=""
            index={currentQuestionIndex + 1}
            question={questionsList?.[currentQuestionIndex]?.question as string}
            options={questionsList?.[currentQuestionIndex]?.options || []}
            onSelectOption={(selectedOption) => {
              const correctValue = questionsList?.[currentQuestionIndex]?.options?.find(e => e?.isCorrect)?.value;          
              if (correctValue) {
                gameUuid &&  updateData(gameUuid.toString(), { [gameLevel]: { answered: generateRandomFourDigitNumber() } });
                // showMessage(`${selectedOption === correctValue ? '🎉 Horray! Correct Answer ' : "Correct Answer "} -${capitalizeFirstLetter(correctValue)}`, 2000)
                handleSelectOption(selectedOption, correctValue);
              }
            }}
            selectedOption={selectedOption}

            time={time}
            Time={1200}
            totalQuestions={totalQuestions}
            level={gameLevel}
          />
        
          
          {/* <ControlBar /> */}
        </div>
      ) : (
        totalQuestions > 0 && (
          <ResultsPopup player1={player1} player2={player2} context={1} />
        )
      )}
      {/* {showConfetti && <LottieConfetti />} */}
      {showPopup && <AmazonPopup />}
    </div>
  );
};

export default Quiz;


const capitalizeFirstLetter = (str:string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};