"use client";

import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useRealtimeDatabase } from '../../hooks/useRealtimeDatabase';
import { useAppSelector } from '../../store/store';
import { setGamesConfig } from '../../store/gameConfigSlice';
import { filterSarathi } from '../../utils/sarathiData';
import { setSarathiProfile } from '../../store/sarathiDetailsSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import RtcClient from '../../components/AudioChat/sdk/rtc-client';
import MeetingComponent from '../../components/MeetingComponent';
import WaitingScreen from '../waitingScreen'; // Import your WaitingScreen component
import StepperComponent from '../../components/Stepper/Stepper';
import { useLazyFetchGamesDetailsQuery } from '../../store/api/fetchGameDetail.slice';
import { useUserInformation } from '../../hooks/UserInformationContext';
import { format } from "date-fns";

const StartPageContent: React.FC<any> = () => {

 
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeSarathiConfig = useAppSelector(state => state.sarathiSlice);
  const { data, updateData } = useRealtimeDatabase('data');
  const rtc = useRef<RtcClient>();
  const searchParams = new URLSearchParams(location.search);
  const [stepperState,setStepperState] = useState(0);
  const gameName = searchParams.get('name') || 'Quiz';
  const level = searchParams.get('level') || '1';
  const duration = searchParams.get('duration') || '20';
  const gameUuid = searchParams.get('uuid');
  const userType = searchParams.get('type');

  const { openUserInformation } = useUserInformation();

  //@ts-ignore
  let gameLevel: number = searchParams.get('level') ? parseInt(searchParams.get('level')) : 1;

  const [fetchGameData, { data: gameDetailData, isFetching, isLoading }] = useLazyFetchGamesDetailsQuery();
  
  const [userName, setUserName] = useState('');
  const [expertId, setexpertId] = useState('');
  
  useEffect(() => {
    //@ts-ignore
    if (gameUuid ) {
      fetchGameData(gameUuid)
    }
  }, [gameUuid])
  useEffect(() => {
    console.log('GAMES_DETAILS',gameDetailData)
    if (gameDetailData?.userId?.name) {
      setUserName(gameDetailData?.userId?.name)
      if(gameDetailData?.sarathiID?._id) {
        setexpertId(gameDetailData?.sarathiID?._id)
        // openUserInformation()
      }
    }
  }, [gameDetailData])
  
  const [tokenState, setTokenState] = useState([]);
  const [micPermissionGranted, setMicPermissionGranted] = useState<boolean>(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [gamesFirebaseData, setGamesFirebaseData] = useState({});
  const [beginGameByUser, setBeginGameByUser] = useState(userType == 'sarathi' ? true : false);
  const [micPermissionStatus, setMicPermissionStatus] = useState<PermissionState>('prompt');
  const initCalled = useRef(false);

  const requestMicPermission = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true,preferCurrentTab:true });
      if (stream) {
        setMicPermissionStatus('granted');
      }
    } catch (error) {
      console.error("Microphone permission request denied:", error);
      setMicPermissionStatus('denied');
    }
  };

  const init = async () => {
    const sarathiDetail = filterSarathi(expertId);
    const gameLink = `https://play.sukoonunlimited.com/${location.search.replace("type=user", "type=sarathi")}`;
    let getSarathiLocalNumber = '';
    try {
      getSarathiLocalNumber = localStorage.getItem("sarathiNumber") || '';
    } catch (error) {}

    if (gameLink && sarathiDetail._id.$oid && userType == 'user' && level == '1') {
      // createGame(sarathiDetail._id.$oid.toString(), userName || '', gameLink.toString()).then(res => {
      //   message.success("Notification Sent to Sarathi, Please wait for 2 Minutes he will be joining Soon ");
      // }).catch(e => {
      //   // Handle error
      // });
    }

    if (sarathiDetail.phoneNumber !== getSarathiLocalNumber) {
      try {
        localStorage.setItem("sarathiNumber", sarathiDetail.phoneNumber.toString());
      } catch (error) {
        // Handle error
      }
    }

    //@ts-ignore
    dispatch(setSarathiProfile(sarathiDetail));
    
    if (userType === 'sarathi' && gameUuid) {
      await updateData(gameUuid.toString(), { [gameLevel]: { isSarathiJoined: true, sarathiName: filterSarathi(expertId)?.name || 'Sarathi' } });
    }
    if (userType === 'user' && gameUuid) {
      await updateData(gameUuid.toString(), { [gameLevel]: { userName: gameDetailData?.userId?.name, isUserJoined: true } });
    }
  };

  useEffect(() => {
    try {
      if (gameUuid && gameLevel) {
        if (data[gameUuid.toString()][gameLevel]) {
          setLoadingPage(false);
          setGamesFirebaseData(data[gameUuid.toString()][gameLevel]);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, [JSON.stringify(data), gameUuid, gameLevel]);

  const handleStart = async () => {
    if (storeSarathiConfig._id && storeSarathiConfig.name && userName && gameUuid && gamesFirebaseData) {
      const sarathiId = storeSarathiConfig._id;
      try {
        const userGamesConfig = {
          expertId,
          gameId: gameUuid.toString(),
          gameLevel: level.toString(),
          //@ts-ignore
          isSarathiJoined: gamesFirebaseData['isSarathiJoined'] || false,
          isUserJoined: true,
          playerId: userName.toLowerCase(),
          gameStarted: true,
        };
        dispatch(setGamesConfig(userGamesConfig));
        await updateData(gameUuid.toString(), { [gameLevel]: userGamesConfig }).then(() => {
          checkGameStart();
        });
      } catch (error) {
        console.error("Error joining the game:", error);
      }
    }
  };

  useEffect(() => {
    if (!initCalled.current && level && expertId && gameUuid && userType && userName) {
    
      init();
      initCalled.current = true;
    }
  }, [level, expertId, gameUuid, userType, userName]);

  useEffect(() => {
    if (data) {

      //@ts-ignore
      if (gamesFirebaseData.gameStarted) {
        checkGameStart()
      }

    }
  }, [gamesFirebaseData])


  const actionClick = async () => {
    const checkPermissions = async () => {
      const hasPermissions = await navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then(() => true).catch(() => false);
      if (hasPermissions) {
        setStepperState(1)
      }
      setShowPermissionRequest(!hasPermissions);
    };
    checkPermissions();
  }


  useEffect(() => {
    if (micPermissionStatus == 'granted') {
      setStepperState(1)
    }

  }, [micPermissionStatus]);

  useEffect(()=>{
    setTimeout(()=>{
      requestMicPermission();
      actionClick()
    },7000)
  },[])
  
  const checkGameStart = async () => {
    if (data && gameUuid && gameLevel) {
      const gameData = data[gameUuid][gameLevel];
      if (gameData && gameData.isSarathiJoined && gameData.isUserJoined) {
        const params = searchParams.toString();
        const gamePath = gameName.toLowerCase().includes('quiz') ? '/quiz' :
          gameName.toLowerCase().includes('match') ? '/cardMatch' :
            gameName.toLowerCase().includes('colour') ? '/colourTrap' :
              gameName.toLowerCase().includes('tricky') ? '/trickyCups' :
                '/quiz';
        navigate(`${gamePath}?${params}`);
      }
    }
  };

  const [showPermissionRequest, setShowPermissionRequest] = useState<boolean>(true);

  const handlePermissionGranted = () => {
    setShowPermissionRequest(false);
  };

  

  if (loadingPage) {
    return <p>Loading...</p>;
  }
  

 


  const ValidationStpe = () => {
    return (
      <div className='flex flex-col max-w-md items-center justify-center' >
        
        
     
        <button
          onClick={() => actionClick()}
          className=" mt-8 p-2 font-normal text-black bg-primaryYellow rounded-lg shadow-lg transition-all duration-300 transform hover:scale-105 hover:shadow-xl"
        >
          Allow browser permissions to play game
        </button>

        <div className='bg-white m-4 p-4 mt-8  border-YELLOW border-[1px] rounded-lg max-w-96 '>

          <p className='font-normalFont justify-center  text-justify'>Please note it is mandatory to allow
            Mic and Camera permissions to
            initiate game
          </p>
          <div className='mt-4 justify-center text-left '>
            <p className='font-normalFont text-justify inline-grid'>For assistance regarding
              browser permissions, call Sukoon Assistance +919110673203 </p>


          </div>
        </div>
        
      </div>
    )
  }

  const arrayForUserDetails = ['gender', 'language_preference', 'locationcity', 'marital_status', 'values', "pain_pointschallenges", "physical_state_of_being", "needs", "motivators"]

  const BeginGame = () => {
    return (
      <div className="flex flex-col h-screen my-auto items-center mx-auto max-w-screen-md">
        {(userType === 'user' && beginGameByUser === false) ? (
          // @ts-ignore
          <WaitingScreen startGame={handleStart} isSarathiJoined={gamesFirebaseData?.isSarathiJoined || false} onStartGame={() => setBeginGameByUser(true)} level={gameLevel} expertDescription={gameDetailData?.sarathiID?.description || ''} expertImage={gameDetailData?.sarathiID?.profile || ''} expertId={expertId} expertName={gameDetailData?.sarathiID?.name || ''} userName={userName} userType={userType} />
        ) : (
          <div className='w-full flex flex-col items-center overflow-auto md:mt-10'>
            {(showPermissionRequest === false && beginGameByUser === true) && expertId && gameDetailData &&  <MeetingComponent GameData={gameDetailData} />}
            <div className="flex flex-col h-full my-auto items-center justify-center">
              <div className="flex flex-col bg-YELLOW p-4 md:p-10 pt-5 items-center rounded-xl md:max-w-3xl">
                <div className="px-5 rounded relative top-5 text-neutral-800 text-[22px] font-heavyFont bg-orange tracking-wide">{userName}</div>
                <div className="flex flex-col md:items-center bg-orange md:flex-row px-5 py-10 rounded">
                  <div className="flex flex-row mx-5 items-center justify-center">
                      {userType === 'sarathi' ? <div className="flex flex-col gap-2">
                        <div className="flex flex-row gap-2">
                          <p className="text-black  font-normalFont tracking-wide">FROM:</p>
                          <h1 className="text-black font-normalFont tracking-wide">{gameDetailData?.userId?.city || ''}</h1>
                        </div>
                        <div className="flex flex-row gap-2">
                          <p className="text-black  font-normalFont tracking-wide">DOB:</p>
                          <h1 className="text-black font-normalFont tracking-wide">{gameDetailData?.userId?.birthDate ? format(gameDetailData?.userId?.birthDate, "dd-MM-yyyy") : "-" || ''}</h1>
                        </div>
                      </div> : <img className="md:max-w-52 h-28 md:h-auto rounded-xl" src={`${storeSarathiConfig.profile}`} alt="sarathi icon" />
                      }
                  </div>
                  {userType === "user" && <div className="border-t md:border-t-0 md:border-l border-black w-full md:w-auto h-auto md:h-full my-4 md:mx-4" />}
                  {userType === "user" && <div className="grid md:items-start justify-center md:justify-start gap-2 mx-5">
                    <span className='text-black text-xl font-normalFont tracking-wide text-center'>Say <span>Hello</span> to <span>{storeSarathiConfig.name}</span></span>
                    <span className='text-black text-xl font-normalFont tracking-wide text-center text-wrap'>{storeSarathiConfig.description?.split(". ")[0].toString()}.</span>
                  </div>}
                </div>
              </div>
              {data && gameUuid && userType === 'sarathi' && (
                <>
                <Button disabled className="text-white text-2xl mt-8 px-7 py-2 rounded font-heavyFont bg-tealDark tracking-wider">
                  {/* @ts-ignore */}
                  {gamesFirebaseData?.isUserJoined ? `Say 👋🏻 to ${gamesFirebaseData?.userName}` : "Waiting for User"}
                  
                </Button>
                 

                   
                </>
              )}
              {data && gameUuid && userType === 'user' && (
                <Button onClick={handleStart} className="text-white text-2xl mt-2 md:mt-8 px-7 py-2 rounded font-heavyFont bg-tealDark tracking-wider">
                  {/* @ts-ignore */}
                  {data && gamesFirebaseData && gamesFirebaseData?.isSarathiJoined ? "Let's Play" : "Waiting for Sarathi"}
                </Button>
              )}
            </div>


            {userType === 'sarathi'  && <div className="mt-4 p-6   border-[1px]  border-YELLOW rounded-lg m-2">
           
            <ul className="list-disc  space-y-2 justify-start text-left">
              <li className='font-normalFont'>{`Adjust the volume of your phone to make sure you can hear  ${userName}`}</li>
              <li  className='font-normalFont'>Make sure your internet connection is stable.</li>
                {/* @ts-ignore */}
             {gamesFirebaseData?.isUserJoined && <li  className='font-normalFont'>Can't hear user? Refresh page</li>}
              <li className='font-normalFont'>
                Still facing challenges? Call Sukoon Assistance at 
                <span className="font-normal"> +91 9110673203</span>.
              </li>
            </ul>
          </div>}
            {/* @ts-ignore */}
            {/* {userType && <UserAndSarathiInstructions role={userType} sarathiDetails={storeSarathiConfig} />} */}
          </div>
        )}
      </div>
    );
  }


 return (
    < div className="flex flex-col h-screen my-auto items-center mx-auto max-w-screen-md">
      <div className='mt-2 flex flex-col  max-w-md justify-center'>
       <div className='flex flex-row   items-center justify-between  bg-white shadow-sm rounded-b-md mb-4 pl-8 pr-8'>
         <img src={'/logo.svg'} alt="Logo" width={80} height={40} className="right-0" />
         <p className='text-lg text-center'>{gameName || 'Quiz'}</p>
         {/* <Button className='bg-YELLOW p-2 rounded-md' onClick={() => window.open('tel:+919110673203', '_self')} >Help</Button> */}
       </div>
      
        <StepperComponent currentStep={stepperState} headings={["Prepare For Games", "Meet Sarathi", "Play Game"]} variant='Colorlib' />
      </div>
     {stepperState === 0 &&  <ValidationStpe />}
     {stepperState === 1 &&  <BeginGame />}
    </div>
  );
};

export default StartPageContent;
