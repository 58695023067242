import axios from "axios";

const BACKEND_URL = 'https://prod-backend.sukoonunlimited.com';
export const axiosInstance = axios.create({
  baseURL: "https://6x4j0qxbmk.execute-api.ap-south-1.amazonaws.com/main/actions",
  timeout: 60 * 1000,
  maxRedirects: 0,
});


export const updateSarathiStatus = async (id: string, status: boolean) => {
  return await axios({
    method: "POST",
    baseURL: BACKEND_URL,
    url: "api/call/game-change-sarathi-status",
    data:{
      expertId:id,
      status:status
    }
  })
}


export const createGame = async (sarathiID: string, userName: string = '', gameLink: string) => {
  return axios({
    method: "POST",
    baseURL: BACKEND_URL,
    url: 'api/expert/createNewGames',
    data: {
      sarathiID: sarathiID,
      gameLink: gameLink,
      userName: userName
    }
  }).then(res => {
    return res.data
  }).catch(err => {
    return { message: "SarathiID & gameLink not passed", err }
  })
}