import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";


import { Middleware, MiddlewareAPI, isRejected } from "@reduxjs/toolkit";
import sarathiDetailsSlice from "./sarathiDetailsSlice";
import gamesConfigSlice from "./gameConfigSlice";
import { fetchGamesDetails } from "./api/fetchGameDetail.slice";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action: any) => {
    const { dispatch } = api;

    if (isRejected(action)) {

    }

    return next(action);
  };

export const store = configureStore({
  reducer: {
    sarathiSlice: sarathiDetailsSlice,
    gamesConfigSlice: gamesConfigSlice,
    [fetchGamesDetails.reducerPath]: fetchGamesDetails.reducer
  },
  //@ts-ignore
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(
      fetchGamesDetails.middleware,
      rtkQueryErrorLogger
    );
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
