'use client'
import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { message } from 'antd';
import styled from 'styled-components';
import {
  MediaType,
  onUserJoinedEvent,
  onUserLeaveEvent,
  PlayerEvent,
  AutoPlayFailedEvent,
} from '@byteplus/rtc';

import { ControlBar, AutoPlayModal } from '../../modules';

import { Context } from '../../context';

import RTCComponent from '../../sdk/rtc-component';
import { RTCClient } from '../../app-interfaces';
import { streamOptions } from './constant';

import MediaPlayer from '../../components/MediaPlayer';
import { removeLoginInfo } from '../../utils';
import { generateToken } from '../../../../utils/webrtc';
import { GameSession } from '../../../../store/api/fetchGameDetail.slice';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  gap: 8px;
`;

const Item = styled.div`
  flex: 1;
  width: calc(50% - 8px);
  min-width: calc(50% - 8px);
  max-width: calc(50% - 8px);
  height: calc(50% - 8px);
  position: relative;
`;

// config={config.tokens} roomId={config.roomId} appId={config.appId} 
interface meetingProps {
  config: any;
  roomId: string;
  userId: string;
  sarathiId?:string;
  appId: string;
  gameData:GameSession
}
const Meeting: React.FC<meetingProps> = (props: meetingProps) => {
  const { config, roomId, appId, userId } = props;
  const { setJoin } = useContext(Context);
  const [isMicOn, setMicOn] = useState<boolean>(true);
  const [isVideoOn, setVideoOn] = useState<boolean>(false);
  const rtc = useRef<RTCClient>();
  const [autoPlayFailUser, setAutoPlayFailUser] = useState<string[]>([]);
  const playStatus = useRef<{ [key: string]: { audio: boolean; video: boolean } }>({});
  const autoPlayFailUserdRef = useRef<string[]>([]);

  const [remoteStreams, setRemoteStreams] = useState<{
    [key: string]: {
      playerComp: React.ReactNode;
    };
  }>({});

  const leaveRoom = useCallback(
    (refresh: boolean) => {
      if (!rtc.current) return;

      // off the event
      rtc.current.removeEventListener();

      rtc.current.leave();
      if (!refresh && setJoin) {
        setJoin(false);
        removeLoginInfo();
      }

      setAutoPlayFailUser([]);
      // setJoinFailReason('');
    },
    [rtc, setJoin]
  );
  // alert(111);

  // useEffect(() => {
  //   if (sessionStorage.getItem('store')) {
  //     const a = sessionStorage.getItem('store');
  //     a && alert(a);
  //   }
  // }, []);
  /**
   * @brief call leaveRoom function when the browser window closes or refreshes
   */
  const leaveFunc = () => {
    leaveRoom(true);
    if(window) {
      sessionStorage.setItem('store', JSON.stringify({ test: new Date().toString() }));
    }
    
  };
  // useEffect(() => {
  //   window.addEventListener('pagehide', leaveFunc);
  //   return () => {
  //     leaveRoom(true);
  //     window.removeEventListener('pagehide', leaveFunc);
  //   };
  // }, [leaveRoom]);

  const handleUserPublishStream = useCallback(
    (stream: { userId: string; mediaType: MediaType }) => {
      const userId = stream.userId;
     
    },
    [remoteStreams]
  );

  /**
   * @brief remove stream & update remote streams list
   * @param {Event} event
   */
  const handleUserUnpublishStream = (event: { userId: string; mediaType: MediaType }) => {
    const { userId, mediaType } = event;

    if (mediaType & MediaType.VIDEO) {
      // rtc.current?.setRemoteVideoPlayer(userId, undefined);
    }
  };

  const handleUserStartVideoCapture = (event: { userId: string }) => {
    const { userId } = event;

    if (remoteStreams[userId]) {
      // rtc.current?.setRemoteVideoPlayer(userId, `remoteStream_${userId}`);
    }
  };

  /**
   * Remove the user specified from the room in the local and clear the unused dom
   * @param {*} event
   */
  // const handleUserStopVideoCapture = (event: { userId: string }) => {
  //   const { userId } = event;

  //   rtc.current?.setRemoteVideoPlayer(userId, undefined);
  // };

  const handleUserJoin = (e: onUserJoinedEvent) => {
    const { userInfo } = e;
    const remoteUserId = userInfo.userId;

    if (Object.keys(remoteStreams).length < 3) {
      if (remoteStreams[remoteUserId]) return;
      remoteStreams[remoteUserId] = {
        playerComp: <MediaPlayer userId={remoteUserId} />,
      };

      setRemoteStreams({
        ...remoteStreams,
      });
    }
  };

  // useEffect(() => {
  //   const streams = Object.keys(remoteStreams);
  //   const _autoPlayFailUser = autoPlayFailUser.filter(
  //     (item) => streams.findIndex((stream) => stream === item) !== -1
  //   );
  //   setAutoPlayFailUser([..._autoPlayFailUser]);
  // }, [remoteStreams]);

  const handleUserLeave = (e: onUserLeaveEvent) => {
    const { userInfo } = e;
    const remoteUserId = userInfo.userId;
    if (remoteStreams[remoteUserId]) {
      delete remoteStreams[remoteUserId];
    }
    setRemoteStreams({
      ...remoteStreams,
    });
  };

  const init = async () => {
    setTimeout(()=>{
      (async () => {
        if (!roomId || !userId || !rtc.current) return;
        // rtc.current.bindEngineEvents();
        let tokenApp  = await generateToken(roomId,userId)
        tokenApp = tokenApp.output_details?.token;
        
        let token = tokenApp;
       
        config?.tokens?.forEach((item: any) => {
          if (item.userId === userId) {
            token = item.token;
          }
        });
  
      
        
        rtc.current
          .join((token as any) || null, roomId, userId)
          .then(() =>
            rtc?.current?.createLocalStream &&   rtc?.current?.createLocalStream(userId, (res: any) => {
              const { code, msg, devicesStatus } = res;
              if (code === -1) {
                alert(msg);
                setMicOn(true);
                // setVideoOn(false);
              }
            })
          )
          .catch((err: any) => {
            // alert('googogo');
            console.log('err', err);
            leaveRoom(false);
            // setJoinFailReason(JSON.stringify(err));
          });
      })();
    },100);
  }
  useEffect(() => {
    init()
  }, [roomId, userId]);

  const changeMicState = useCallback((): void => {
    if (!rtc.current) return;
    rtc.current.changeAudioState(!isMicOn);
    setMicOn(!isMicOn);
  }, [isMicOn, rtc]);

  // const changeVideoState = useCallback((): void => {
  //   if (!rtc.current) return;
  //   rtc.current.changeVideoState(!isVideoOn);
  //   setVideoOn(false);
  // }, [isVideoOn, rtc]);

  const handleEventError = (e: any, VERTC: any) => {
    if (e.errorCode === VERTC.ErrorCode.DUPLICATE_LOGIN) {
      // message.error('你的账号被其他人顶下线了');
      leaveRoom(false);
    }
  };

  const handleAutoPlayFail = (event: AutoPlayFailedEvent) => {
    const { userId, kind } = event;

    let playUser = playStatus.current?.[userId] || {};
    playUser = { ...playUser, [kind]: false };
    playStatus.current[userId] = playUser;

    addFailUser(userId);
  };

  const addFailUser = (userId: string) => {
    const index = autoPlayFailUser.findIndex((item) => item === userId);
    if (index === -1) {
      autoPlayFailUser.push(userId);
    }
    setAutoPlayFailUser([...autoPlayFailUser]);
  };

  const playerFail = (params: { type: 'audio' | 'video'; userId: string }) => {
    const { type, userId } = params;
    let playUser = playStatus.current?.[userId] || {};

    

    playUser = { ...playUser, [type]: false };

    const { audio, video } = playUser;

    if (audio === false || video === false) {
      addFailUser(userId);
    }
  };

  const handlePlayerEvent = (event: PlayerEvent) => {
    const { userId, rawEvent, type } = event;

    console.log('handlePlayerEvent', event, userId, type, rawEvent);

    let playUser = playStatus.current?.[userId] || {};

    if (!playStatus.current) return;

    if (rawEvent.type === 'playing') {
      playUser = { ...playUser, [type]: true };
      const { audio } = playUser;
      if (audio !== false ) {
        const _autoPlayFailUser = autoPlayFailUserdRef.current.filter((item) => item !== userId);
        setAutoPlayFailUser([..._autoPlayFailUser]);
      }
    } else if (rawEvent.type === 'pause') {
      playerFail({ userId, type });
    }

    playStatus.current[userId] = playUser;
  };

  const handleAutoPlay = () => {
    const users: string[] = autoPlayFailUser;
    if (users && users.length) {
      users.forEach((user) => {
        rtc.current?.engine.play(user);
      });
    }
    setAutoPlayFailUser([]);
  };

  // useEffect(() => {
  //   autoPlayFailUserdRef.current = autoPlayFailUser;
  // }, [autoPlayFailUser]);

  return (
    <div className='w-0 h-0'>
      <RTCComponent
        onRef={(ref: any) => (rtc.current = ref)}
        sarathiId={props.sarathiId}
        config={{
          ...config,
          roomId,
        }}
        streamOptions={streamOptions}
        handleUserPublishStream={handleUserPublishStream}
        handleUserUnpublishStream={handleUserUnpublishStream}
        // handleUserStartVideoCapture={handleUserStartVideoCapture}
        // handleUserStopVideoCapture={handleUserStopVideoCapture}
        handleUserJoin={handleUserJoin}
        handleUserLeave={handleUserLeave}
        handleEventError={handleEventError}
        handleAutoPlayFail={handleAutoPlayFail}
        handlePlayerEvent={handlePlayerEvent}
      />
      <Container className='bg-transparent w-0 h-0'>
        <Item>
          <div
            style={{
              width: '0',
            }}
            id={'local-player'}
          >
          </div>
        </Item>
        {Object.keys(remoteStreams)?.map((key) => {
          const Comp = remoteStreams[key].playerComp;
          return <Item key={key}>{Comp}</Item>;
        })}
      </Container>
      
      <AutoPlayModal handleAutoPlay={handleAutoPlay} autoPlayFailUser={autoPlayFailUser} />
    </div>
  );
};

export default Meeting;
