import React, { createContext, useMemo, useState } from "react";

export const TurnContext = createContext({
  turnCounter: 0,
  isUsersTurn: false,
  isSarathisTurn: false,
  handleTurnChange: () => {},
});

//@ts-ignore
const TurnProvider = ({ children }) => {
  const [turnCounter, setTurnCounter] = useState(0);

  const handleTurnChange = () => {
    setTurnCounter(turnCounter + 1);
  };

  const isUsersTurn = useMemo(() => turnCounter % 2 === 0, [turnCounter]);
  const isSarathisTurn = useMemo(() => turnCounter % 2 !== 0, [turnCounter]);

  return (
    <TurnContext.Provider
      value={{
        turnCounter,
        handleTurnChange,
        isUsersTurn,
        isSarathisTurn,
      }}
    >
      {children}
    </TurnContext.Provider>
  );
};

export default TurnProvider;
