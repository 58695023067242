'use client'
/**
 * @brief get query string
 * @param {string} name
 * @returns {string | null}
 */


export function removeLoginInfo() {
  const variable = ['roomId', 'uid'];
  variable.forEach((v) => sessionStorage.removeItem(v));
}

export function setSessionInfo(params: { [key: string]: any }) {
  Object.keys(params).forEach((key) => {
    try {
      if (window) {
        sessionStorage.setItem(key, params[key]);
      }
    } catch (error) {
      
    }
    

  });
}





