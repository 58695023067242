import React from 'react';

const ProgressBar: React.FC<{ progress: number }> = ({ progress }) => {
    return (
        <div className='max-w-md animate-pop-in shadow-fxl bg-orange rounded-full h-2 my-4'>
            <div className='h-2 bg-green-700 rounded-full' style={{ width: `${progress}%` }}></div>
        </div>
    );
};

export default ProgressBar;