import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sarathiProfile } from "./intrefaces/sarathi.interface";

const initialState: sarathiProfile = {
    _id: ''
}


export const sarathiDetailsSlice = createSlice({
    name: "sarathiConfigSlice",
    initialState,
    reducers: {
        setSarathiProfile: (state, action: PayloadAction<sarathiProfile>) => {
            return {
                ...action.payload
            }
        },
        resetSarathiProfile: () => {
            return initialState
        }
    }
})


export const { resetSarathiProfile, setSarathiProfile } = sarathiDetailsSlice.actions;
export default sarathiDetailsSlice.reducer;