'use client';

import React from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';


const AmazonFinalPopup: React.FC = () => {
    const isDesktop = useMediaQuery(768);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="w-[642px] h-[519px] relative bg-sky-200 rounded-2xl overflow-hidden mx-4">
                <div className="w-[850px] h-[1200px] -left-10 -top-[940px] rotate-[85deg] absolute origin-center rounded-full border-2 border-neutral-200" />
                <img className='absolute top-2 left-6' src="/images/carbon-close.svg" alt="Amazon Logo" width={40} height={40} />
                <img className='absolute bottom-0 right-0' width={184} height={44} alt='confetti' src="/images/confett.png" />
                <div className='flex flex-col items-center justify-between h-full py-8'>
                    <h1 className='text-tealDark text-2xl font-pixelFont tracking-wide'>CONGRATULATIONS</h1>
                    <div className="w-[68.34px] h-[68.34px] flex flex-col items-center justify-center bg-white rounded-full border-4 border-amber-500 backdrop-blur-[58.91px]">
                        <div className="origin-top-left rotate-[-0.91deg] text-amber-500 text-[10.24px]">COUPONS</div>
                        <img className="w-[48.31px] h-[21.21px] origin-top-left rotate-[-0.91deg]" src="/images/amazon-logo.png" />
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div className='flex'>
                            <h1 className='text-teal-700 text-[26px] font-medium font-heavyFont uppercase tracking-wide'>100</h1>
                            <h1 className={`text-tealDark text-[26px] font-semibold uppercase tracking-wide`}>₹</h1>
                        </div>
                        <h1 className='text-zinc-800 text-xl font-heavyFont tracking-tight'>Worth Amazon coupons</h1>
                    </div>
                    <div className='w-64 border-t border-black' />
                    <h1 className='text-zinc-800 text-lg font-heavyFont tracking-tight'>Received on 21 May 2024</h1>
                    <div className="w-[316px] h-[72px] bg-white rounded-2xl flex items-center justify-center">
                        <div className="text-center text-black text-lg font-heavyFont tracking-tight">The coupon code will be sent to <br />your WhatsApp</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AmazonFinalPopup;