import React, { createContext, useContext, useState, ReactNode, FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFetchGamesDetailsQuery } from '../store/api/fetchGameDetail.slice';
import { Modal } from 'antd';

// Define the context type
interface UserInformationContextType {
  isOpen: boolean;
  openUserInformation: () => void;
  closeUserInformation: () => void;
}

// Create the context
const UserInformationContext = createContext<UserInformationContextType | undefined>(undefined);

// Create the context provider component
export const UserInformationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openUserInformation = () => setIsOpen(true);
  const closeUserInformation = () => setIsOpen(false);

  return (
    <UserInformationContext.Provider value={{ isOpen, openUserInformation, closeUserInformation }}>
      {children}
      {isOpen && <Modal onOk={() => setIsOpen(false)} open={isOpen} onClose={() => setIsOpen(false)} onCancel={() => setIsOpen(false)}><UserInformation /></Modal>}
    </UserInformationContext.Provider>
  );
};

// Custom hook to use the UserInformation context
export const useUserInformation = () => {
  const context = useContext(UserInformationContext);
  if (context === undefined) {
    throw new Error('useUserInformation must be used within a UserInformationProvider');
  }
  return context;
};

// UserInformation component
const UserInformation: FC = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const gameUuid = searchParams.get('uuid');
  const userType = searchParams.get('type');
  //@ts-ignore
  const { data: gameDetail, isFetching, isLoading, isError } = useFetchGamesDetailsQuery(gameUuid);

  if (isLoading) return <p>Loading...</p>;
  if (isFetching) return <p>Fetching...</p>;
  if (isError) return <p>Error fetching data.</p>;

  if (userType !== 'sarathi') {
    return null; // Return null to render nothing if the condition is not met
  }


  return (
    <div className='personInformation m-2'>
      <p>{gameDetail?.userId?.name} | {gameDetail?.userId?.city}</p>
      <div className='mt-2 flex flex-col  rounded-md  p-6  overflow-y-auto'>
        {gameDetail?.userPersona && Object.keys(gameDetail.userPersona).map(element => {
          if (['gender', 'language_preference', 'locationcity', 'marital_status', 'values', "pain_pointschallenges", "physical_state_of_being", "needs", "motivators"].includes(element)) {
            return (
              <div className="flex flex-col justify-center" key={element}>
                <p className='text-green-900 capitalize'>{element.replaceAll("_", " ")}</p>
                {/* @ts-ignore */}
                <p>{gameDetail.userPersona[element]["value"]?.replaceAll("*", '')}</p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default UserInformation;
