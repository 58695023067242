// components/SpinWheel.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Chart, ChartData, ChartOptions } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chart.js/auto';

const SpinWheel: React.FC = () => {
  const wheelRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);
  const [finalValue, setFinalValue] = useState<string>('Click On The Spin Button To Start');
  const [isSpinning, setIsSpinning] = useState<boolean>(false);

  useEffect(() => {
    if (wheelRef.current) {
      const ctx = wheelRef.current.getContext('2d');
      if (ctx) {
        const data: ChartData = {
          labels: [10, 20, 100, 50, 75, 25],
          datasets: [{
            backgroundColor: ['#B4B1E5', '#FAF0E6', '#3F8D6C'],
            data: [16, 16, 16, 16, 16, 16],
          }],
        };

        const options: ChartOptions = {
          responsive: true,
          animation: { duration: 0 },
          plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
            datalabels: {
              color: '#000',
              formatter: (value, context) => context.chart.data.labels ? context.chart.data.labels[context.dataIndex] : '',
              font: { size: 24 },
            },
          },
        };

        if (chartRef.current) {
          chartRef.current.destroy();
        }

        chartRef.current = new Chart(ctx, {
          type: 'pie',
          data,
          options,
          plugins: [ChartDataLabels],
        });
      }
    }
  }, []);

  const rotationValues = [
    { minDegree: 0, maxDegree: 30, value: 2 },
    { minDegree: 31, maxDegree: 90, value: 1 },
    { minDegree: 91, maxDegree: 150, value: 6 },
    { minDegree: 151, maxDegree: 210, value: 5 },
    { minDegree: 211, maxDegree: 270, value: 4 },
    { minDegree: 271, maxDegree: 330, value: 3 },
    { minDegree: 331, maxDegree: 360, value: 2 },
  ];

  const valueGenerator = (angleValue: number) => {
    for (let i of rotationValues) {
      if (angleValue >= i.minDegree && angleValue <= i.maxDegree) {
        setFinalValue(`Value: ${i.value}`);
        setIsSpinning(false);
        break;
      }
    }
  };

  const handleSpin = () => {
    if (isSpinning) return;
    setIsSpinning(true);
    setFinalValue('Good Luck!');
    let randomDegree = Math.floor(Math.random() * 360);
    let rotation = 0;
    let count = 0;
    let resultValue = 101;

    const interval = setInterval(() => {
      rotation += resultValue;
      if (wheelRef.current) {
        wheelRef.current.style.transform = `rotate(${rotation}deg)`;
      }
      if (rotation >= 360) {
        rotation = 0;
        count += 1;
        resultValue -= 5;
      } else if (count > 15 && rotation === randomDegree) {
        valueGenerator(randomDegree);
        clearInterval(interval);
      }
    }, 10);
  };

  return (
    <div className="w-[360px] mx-auto rounded-lg">
      <div className="relative">
        <canvas id="wheel" ref={wheelRef} className="w-full h-full"></canvas>
        <button
          id="spin-btn"
          className="absolute inset-0 w-[68.34px] h-[68.34px] rounded-full shadow-md text-lg 
          font-semibold text-orange-800 transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          onClick={handleSpin}
          disabled={isSpinning}
        >
          <div className="w-[68.34px] h-[68.34px] flex flex-col items-center justify-center bg-orange rounded-full border-4 border-amberLight backdrop-blur-[58.91px]">
            <div className="origin-top-left rotate-[-0.91deg] text-amber-500 text-[10.24px]">COUPONS</div>
            <img className="w-[48.31px] h-[21.21px] origin-top-left rotate-[-0.91deg]" src="/images/amazon-logo.png" />
          </div>
        </button>
      </div>
      {/* <div id="final-value" className="text-center mt-4 text-lg font-medium text-gray-800">
        <p>{finalValue}</p>
      </div> */}
    </div>
  );
};

export default SpinWheel;
