import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReduxProvider } from './store/StoreProvider';
import { FlashMessageProvider } from './context/FlashMessageContext';
import { UserInformationProvider } from './hooks/UserInformationContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ReduxProvider>
      <FlashMessageProvider>
        <UserInformationProvider>
          <App />
        </UserInformationProvider>
      </FlashMessageProvider>
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
