import React from 'react';

interface IPlayerSingle {
    isUsersTurn: boolean,
    showUserDetail: () => void,
    player1: any,
    firebaseGameData: any
}


const PlayerSingle = (props: IPlayerSingle) => {

    let { isUsersTurn, player1, showUserDetail, firebaseGameData } = props;
    return (
        <div
            className={`flex flex-col items-center h-[84px] mb-4 ${isUsersTurn ? "opacity-100" : "opacity-80"
                }`}
        >
            <div className={`size-20 relative`}>
                {<img
                    src={player1?.avatar.length > 10 ? player1?.avatar : 'jigsaw.png'}
                    onClick={showUserDetail}
                    alt={player1.name?.replaceAll("Sarathi",'')}
                    className={`size-20 rounded mr-2 object-cover object-center ${isUsersTurn ? "border-[1px] p-1 border-YELLOW" : "border-[1px] p-1 rounded-sm border-white"
                        }`}
                />}
            </div>
            <div className="flex flex-col justify-between items-stretch ml-3 mt-1 h-full w-20">
                <div className="font-normalFont text-center leading-none md:text-xl tracking-wide whitespace-nowrap overflow-hidden text-ellipsis">
                    {player1.name?.replaceAll("Sarathi",'')}
                </div>
                <div className="flex flex-row font-normalFont self-center mt-1 ">
                    <h3 className="text-center font-normalFont bg-purple-200 rounded-md w-[40px] h-[18px] border-black border-[1px] text-xs">{isUsersTurn ? firebaseGameData?.userScore: firebaseGameData?.sarathiScore }</h3>
                </div>
            </div>
        </div>
    )
}

export default PlayerSingle