import { useState, useEffect } from 'react';

const useMediaQuery = (width: number): boolean => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = (e: MediaQueryListEvent) => {
        if (e.matches) {
            setTargetReached(true);
        } else {
            setTargetReached(false);
        }
    };

    useEffect(() => {
        const media = window.matchMedia(`(min-width: ${width}px)`);
        media.addEventListener('change', updateTarget);

        if (media.matches) {
            setTargetReached(true);
        }

        return () => media.removeEventListener('change', updateTarget);
    }, []);

    return targetReached;
};

export default useMediaQuery;
