import { Modal } from 'antd';
import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';

interface FlashMessageContextType {
  showMessage: (message: string, duration?: number) => void;
}

const FlashMessageContext = createContext<FlashMessageContextType | undefined>(undefined);

export const useFlashMessage = (): FlashMessageContextType => {
  const context = useContext(FlashMessageContext);
  if (context === undefined) {
    throw new Error('useFlashMessage must be used within a FlashMessageProvider');
  }
  return context;
};

interface FlashMessageProviderProps {
  children: ReactNode;
}

export const FlashMessageProvider: React.FC<FlashMessageProviderProps> = ({ children }) => {
  const [flashMessage, setFlashMessage] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const showMessage = useCallback(
    (message: string, duration = 3000) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      setFlashMessage(message);
      setShow(true);

      const id = setTimeout(() => {
        setShow(false);
        const hideTimeout = setTimeout(() => setFlashMessage(null), 500); // Delay to allow animation
        setTimeoutId(hideTimeout);
      }, duration);

      setTimeoutId(id);
    },
    [timeoutId]
  );

  return (
    <FlashMessageContext.Provider value={{ showMessage, }}>
      {children}
      {flashMessage && (
        <div
          className={`fixed bottom-0 left-0 right-0 mx-auto bg-white text-white text-center py-4 px-6 w-full md:w-2/3 lg:w-1/2 h-1/2 rounded-t-lg shadow-lg transition-opacity duration-500  z-50 ${show ? 'opacity-100' : 'opacity-0'
            }`}
          style={{ maxWidth: '100%' }}
        >
          <div className='bg-tealDark font-normalFont p-5 rounded-md'> {flashMessage} </div>
        </div>
      )}
    </FlashMessageContext.Provider>
  );
};
