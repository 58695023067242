// components/UserInformationButton.tsx
import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useUserInformation } from '../hooks/UserInformationContext';

const UserInformationButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
    const searchParams = new URLSearchParams(window.location.search);
    const userType = searchParams.get('type');
    const { openUserInformation } = useUserInformation();

    const handleClick = () => {
        openUserInformation();
        if (onClick) {
            onClick(); // Call any additional click handler if provided
        }
    };

    return (
        userType === 'sarathi' ? <button onClick={handleClick} className='z-50 flex flex-row items-center text-left gap-2 md:gap-5 h-full p-3'>
            <InfoOutlinedIcon className='w-16 h-16 bg-tealDark text-bgColor rounded-full' />
            <h1>User Info</h1>
        </button> : <></>
    );
};

export default UserInformationButton;
