import { axiosInstance } from "../axiosHelper";
import { GameConfigResponse, GameTypes } from "./config.interface";

export const getGameConfig = async (gameType: GameTypes) => {
  try {
    const data = await axiosInstance.get<GameConfigResponse>(
      `game_config?game_type=${gameType}`
    );
    return data?.data;
  } catch (error) {
    console.log(error, "get game config error");
  }
};
