import React, { useEffect } from 'react';

import './App.css';
import RootLayout from './app/layout';
import { ReduxProvider } from './store/StoreProvider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StartPage from './app/startPage/page';
import QuizComponent from './app/quiz/page';
import { UserInformationProvider } from './hooks/UserInformationContext';
function App() {
  useEffect(() => {
    const handleBeforeUnload = (event:any) => {
      // Trigger your event here
      console.log("User is closing the tab");

      // Optionally, you can set a message to display to the user
      const message = "Are you sure you want to leave?";
      event.returnValue = message;
      return message;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  return (
    <Router>
       <UserInformationProvider>
      <Routes>
       
        <Route path="/" element={<StartPage />} />
        <Route path="/quiz" element={<QuizComponent />} />
        
      </Routes>
      </UserInformationProvider>
    </Router>
  );
}

export default App;
