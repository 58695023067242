"ue client";

import React, { useEffect } from "react";
// import DesktopTimer from "@/components/DesktopTimer";
// import useMediaQuery from "@/hooks/useMediaQuery";

// import { TurnContext } from "@/context/TurnContext";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useRealtimeDatabase } from "../../hooks/useRealtimeDatabase";
import { useLocation } from 'react-router-dom';
import { useUserInformation } from "../../hooks/UserInformationContext";
const Player: React.FC<PlayerProps> = ({ player1, player2, Time, time, currentQuestionNumber = 0 }) => {
  const isDesktop = useMediaQuery(768);
  const { data, updateData } = useRealtimeDatabase('data');
  // const { isSarathisTurn, isUsersTurn } = useContext(TurnContext);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const gameUuid = searchParams.get('uuid');
  //@ts-ignore
  let gameLevel: number = searchParams.get('level') ? parseInt(searchParams.get('level')) : 1;
  //@ts-ignore
  const firebaseGameData = data?.[gameUuid][gameLevel];
  useEffect(() => {
   
  }, [JSON.stringify(firebaseGameData)])

  let isUsersTurn = currentQuestionNumber % 2 !== 0 ? true : false;
  let isSarathisTurn = currentQuestionNumber % 2 === 0 ? true : false;

  const { openUserInformation } = useUserInformation();
  const showUserDetail = () => {
  
    openUserInformation()
  }

  
  return (
    <div className="flex justify-between h-fit items-center  p-2 rounded-xl mb-4">
      
      <div
        className={`flex flex-col items-center h-[84px] ${isUsersTurn ? "opacity-100" : "opacity-80"
          }`}
      >
        <div className={`size-20 relative`}>
          {<img
            src={'jigsaw.png'}
            onClick={showUserDetail}
            alt={player1.name}
            className={`size-20 rounded mr-2 object-cover object-center ${isUsersTurn ? "border-[1px] p-1 border-YELLOW" : "border-[1px] p-1 rounded-sm border-white"
              }`}
          />}
        </div>
        <div className="flex flex-col justify-between items-stretch ml-3 mt-1 h-full w-20">
          <div className="font-normalFont text-center leading-none md:text-xl tracking-wide">
            {player1.name.split(" ")[0]}
          </div>
          <div className="flex flex-row font-normalFont self-center mt-1 ">
            <h3 className="text-center font-normalFont bg-purple-200 rounded-md w-[40px] h-[18px] border-black border-[1px] text-xs">{firebaseGameData?.userScore || 0}</h3>
          </div>
        </div>
      </div>

      
      <div
        className={`flex flex-col items-center h-[84px] ${isSarathisTurn ? "opacity-100" : "opacity-80"
          }`}
      >

       
        <div className={`size-20 relative`}>
          <img
            src={player2.avatar}
            alt={player2.name}
            className={`size-20 rounded mr-2 object-cover object-center ${isSarathisTurn ? "border-[1px] p-1 border-YELLOW" : "border-[1px] p-1 rounded-sm border-white"
              }`}
          />
          </div>
          <div className="flex flex-col justify-between items-stretch ml-3 mt-1 h-full w-20">
          <div className="font-normalFont leading-none text-center md:text-xl tracking-wide">
            {player2.name.replaceAll("Sarathi",'')}
          </div>
          <div className="flex flex-row font-normalFont self-center mt-1 ">
            <h3 className="text-center font-normalFont bg-purple-200 rounded-md w-[40px] h-[18px] border-black border-[1px] text-xs">{firebaseGameData?.sarathiScore || 0}</h3>
          </div>
          </div>
        </div>
      </div>
  );
};

export default Player;
