import React, { useState, useEffect, useContext } from "react";
// import MobileTimer from "@/components/MobileTimer";
// import useMediaQuery from "@/hooks/useMediaQuery";
import { QuestionProps } from "./interface";
// import { QuizOption } from "@/hooks/quiz/interface";
// import { TurnContext } from "@/context/TurnContext";
// import { useRealtimeDatabase } from "@/hooks/useRealtimeDatabase";

import useMediaQuery from "../../hooks/useMediaQuery";
import { useRealtimeDatabase } from "../../hooks/useRealtimeDatabase";
import { QuizOption } from "../../hooks/quiz/interface";
import { useAppSelector } from "../../store/store";
import { TurnContext } from "../../context/TurnContext";
import MobileTimer from "../MobileTimer";
import { useLocation } from 'react-router-dom';

// import { useAppSelector } from "@/store/store";

const Question: React.FC<QuestionProps> = ({
  question,
  options,
  onSelectOption,
  selectedOption,
  image,
  time,

  Time,
  index,
  totalQuestions,
  level,
}) => {
  const [answered, setAnswered] = useState(false);
  const [shuffledOptions, setShuffledOptions] = useState<QuizOption[]>(options);
  const isDesktop = useMediaQuery(768);

  const { data, updateData } = useRealtimeDatabase('data');
  const location = useLocation();

  // Function to parse query parameters
  const searchParams = new URLSearchParams(location.search);
  const gameName = searchParams.get('name') || 'Default';
  const levelss = searchParams.get('level') || '1';
  const duration = searchParams.get('duration') || '20';
  const expertName = searchParams.get('expertName') || 'John Doe';
  const gameUuid = searchParams.get('uuid');
  const userType = searchParams.get('type');
  const userName = searchParams.get('userName');
  const [enableOptions, setEnableOptions] = useState(true);
  const sarthiInfo = useAppSelector(data => data.sarathiSlice);
  const gameInfo = useAppSelector(data => data.gamesConfigSlice);
  useEffect(() => {
    if (index === 1) {
      if (userType == 'user') setEnableOptions(false);
      if (userType == 'sarathi') setEnableOptions(true);
    }
  }, [gameInfo])
  useEffect(() => {
    const shuffled = options.sort(() => Math.random() - 0.5);

    setShuffledOptions(shuffled);
  }, [options]);


  useEffect(() => {
    if (index % 2 !== 0) {
      if (userType == 'user') setEnableOptions(false);
      if (userType == 'sarathi') setEnableOptions(true);
    }
    if (index % 2 == 0) {
      if (userType == 'user') setEnableOptions(true);
      if (userType == 'sarathi') setEnableOptions(false);
    }
  }, [index])
  const handleSelectOption = (option: string) => {

    if (!answered) {
      onSelectOption(option);
      setAnswered(true);
    }
  };

  const { isSarathisTurn } = useContext(TurnContext);
  return (
    <div
      className={`rounded-t-[20px] md:rounded-[20px]  px-10  mt-5 -mb-2 md:my-5`}
    >
      <div id='local-player' />
      {/* {gameUuid &&  data &&  data[gameUuid]['isSarathiTurn'].toString()} */}
      <div className="flex flex-row    items-center">
        {/* <div className="flex flex-row  items-center">
          <div className="text-lg font-normalFont sm:font-heavyFont mb-2  w-fit py-2 tracking-wider px-3 rounded-lg">
            Level {levelss}
          </div>
          <div className="text-md mb-2 scroll ml-10 font-heavyFont sm:font-heavyFont">
            Question {index} of {totalQuestions}
          </div>
        </div> */}
        {/* {!isDesktop && <MobileTimer time={time} Time={Time} />} */}
      </div>
      <div className="flex flex-col items-center md:flex-row">
        {/* <img
          src={image}
          alt="Question Image"
          className="w-fit h-[217px] md:w-[344px] md:h-[267px] rounded-xl"
        /> */}
        <div className="md:ml-5 flex flex-col  w-screen ">
          <div className="bg-white rounded-lg  s mb-8 pr-4 pl-4">
            <div className="flex flex-row justify-between">
              <p>  {index}/{totalQuestions}</p>
              <p>  Level {levelss}</p>
            </div>
            <hr />
           
          </div>
          <h1 className=" text-2xl font-mediumFont sm:font-heavyFont mb-2 md:mb-0 pr-4 pl-4">{question}</h1>
          <div className="grid xs:grid-cols-2 sm:grid-rows-4 p-4 mb-2 md:mb-0 gap-7 overflow-y-auto">
           
            {options?.map((item, index) => {
              return (
                <button
                  disabled={enableOptions}
                  key={index}
                  className={`font-mediumFont sm:font-heavyFont text-lg break-words
                            rounded-lg py-1 px-4 text-left hover:bg-YELLOW transition capitalize
                            transform tracking-wider disabled:bg-gray-300
                            ${answered &&
                      item?.value === selectedOption &&
                      item?.isCorrect
                      ? "hover:bg-tealDark bg-tealDark text-white shadow-2xl animated-pop"
                      : answered && item?.value === selectedOption
                        ? "hover:bg-red-600 text-white bg-red-600 animated-pop"
                        : "bg-amberLight"
                    }
                            `}
                  onClick={() => handleSelectOption(item?.value)}
                >
                 <p className="font-normalFont text-wrap"> {item?.value}</p>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Question;
