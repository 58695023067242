const sarathiList = [{
    "_id": {
        "$oid": "6604665042f04a057fa20ddc"
    },
    "name": "Sarathi Nishi",
    "phoneNumber": "9431370051",
    "otp": "",
    "expiresOtp": "2024-07-16T09:12:13.913Z",
    "active": true,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Counseling | Philosophy | Psychology",
    "status": "offline",
    "isBusy": false,
    "__v": 2,
    "description": "Meet Sarathi Nishi from Orissa. A beacon of strength and compassion, Nishi hails from Ranchi but currently resides in Bhubaneswar. Nishi's family is her pride- a doting husband working in a large corporate and two professionally accomplished children doing well in diverse lines. Nishi's career journey spans roles in rural development, education administration, and adolescent counseling. A lifelong learner, she finds joy in exploring new places and sharing moments with friends over a cup of tea. Rooted in gratitude and acceptance, Nishi's mission is to spread happiness and promote positive parenting, serving as a role model for detachment and growth.",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032435310_b16e11d0-5c0e-4e74-826e-210cecaec089_Untitled%2Bdesign%2B-%2B6.png",
    "socketId": "",
    "categories": [
        {
            "$oid": "66768da1994dbe114898bfc1"
        },
        {
            "$oid": "66768dca994dbe114898bfc2"
        }
    ],
    "languages": [
        "English, Hindi"
    ],
    "profileCompleted": true,
    "score": 4.3,
    "repeat_score": 0,
    "total_score": 28,
    "calls_share": 0,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/nishi.mp4",
    "closingGreeting": 5.48,
    "flow": 10.12,
    "probability": 18,
    "timeSpent": 12.52,
    "timeSplit": 12.86,
    "tonality": 9.49,
    "userSentiment": 18.72,
    "openingGreeting": 5.64,
    "unlisted": false,
    "type": "expert",
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    }
},
{
    "_id": {
        "$oid": "6604675f42f04a057fa20e09"
    },
    "name": "Sarathi Vandana",
    "phoneNumber": "7596938218",
    "otp": "",
    "expiresOtp": "2024-07-19T10:28:47.585Z",
    "active": true,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Home Maker | Doting Mother | Positive Living",
    "status": "offline",
    "isBusy": false,
    "__v": 2,
    "description": "Meet Sarathi Vandana from Odisha and West Bengal. Devoted to her husband and three children, she finds joy in her family and making people eat! Rooted in culture and values, she navigates life's complexities with patience and adaptability, learning from everyone she crosses paths with. Her mission? To foster genuine connections and share life's joys, be in service to others and offer solace through spirituality. Vandana is not just a deeply warm person; she's a guiding light, radiating positivity and compassion wherever she treads, embodying strength and grace in every step.",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032906347_195e8af7-725d-44fe-b1f2-fd3d49a26afb_Untitled%2Bdesign%2B-%2B10.png",
    "socketId": "",
    "languages": [
        "Hindi, Bengali, Marwari"
    ],
    "profileCompleted": true,
    "score": 3.9,
    "repeat_score": 0,
    "total_score": 26,
    "calls_share": 0,
    "closingGreeting": 6.42,
    "flow": 10.84,
    "probability": 15.94,
    "timeSpent": 10.28,
    "timeSplit": 11.89,
    "tonality": 9.35,
    "userSentiment": 17.85,
    "openingGreeting": 6.25,
    "unlisted": false,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/vandana.MOV",
    "categories": [
        {
            "$oid": "6639cc43f742b02413a5051d"
        },
        {
            "$oid": "6639cc43f742b02413a5050f"
        },
        {
            "$oid": "6639cc43f742b02413a50512"
        },
        {
            "$oid": "66769170bcc12d0a6c13fc7f"
        },
        {
            "$oid": "66768fed994dbe114898bfc4"
        },
        {
            "$oid": "66767225bafddf42c11b4a17"
        }
    ],
    "type": "saarthi",
    "lastModifiedBy": {
        "$oid": "668be3fda130674c57819937"
    }
},
{
    "_id": {
        "$oid": "6604694542f04a057fa2100f"
    },
    "name": "Sarathi Ajitha",
    "phoneNumber": "9249333099",
    "otp": "",
    "expiresOtp": "2024-07-16T10:06:10.097Z",
    "active": true,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": " Travel | Reading | Yoga",
    "status": "offline",
    "isBusy": false,
    "__v": 3,
    "description": "Meet Sarathi Ajitha from Karnataka. Proud wife of an IAF air warrior, mother to 2 well settled kids and care giver to her ageing mom, her family is her cornerstone. Ajitha's career as a teacher spans national and international curricula, reflecting her passion for education. Her diverse interests, from reading, travelling, food to fitness, showcase her zest for life. Valuing honesty, she takes pride in her contributions to education and adult literacy. Ajitha's heart lies in supporting senior citizens, especially those without family support, embodying compassion and empathy in her quest to make a difference in society.",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032140634_75279eab-10ec-42b5-80a0-822b8e8df164_Untitled%2Bdesign%2B-%2B15.png",
    "socketId": "",
    "categories": [
        {
            "$oid": "66766aac712aefd7bc8c1b52"
        },
        {
            "$oid": "66766761bafddf42c11b4a15"
        },
        {
            "$oid": "6639cc43f742b02413a50511"
        },
        {
            "$oid": "6639cc43f742b02413a50525"
        },
        {
            "$oid": "6639cc43f742b02413a50512"
        },
        {
            "$oid": "66768e22049d69a3af61bdd3"
        }
    ],
    "languages": [
        "English,Hindi, Kannada"
    ],
    "profileCompleted": true,
    "score": 4.1,
    "repeat_score": 0,
    "total_score": 27,
    "calls_share": 0,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/ajitha.mp4",
    "closingGreeting": 6.04,
    "flow": 10.23,
    "probability": 16.67,
    "timeSpent": 11.38,
    "timeSplit": 12.88,
    "tonality": 9.31,
    "userSentiment": 18.21,
    "openingGreeting": 6.03,
    "unlisted": false,
    "type": "saarthi",
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    }
},
{
    "_id": {
        "$oid": "66046a3d42f04a057fa21034"
    },
    "name": "Sarathi Abraham",
    "phoneNumber": "9868206095",
    "otp": "",
    "expiresOtp": "2024-07-21T11:46:51.469Z",
    "active": true,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Fitness | Social Work | Finance",
    "status": "offline",
    "isBusy": false,
    "__v": 2,
    "description": "Meet  Retd. Capt. Abraham from Kerala. He is a distinguished Naval Officer with over 32 yrs of experience encompassing technical, logistics, HR, and general administration. His family is spread across continents, while he and his wife call Kochi home. You can talk to him about almost everything - fitness, travelling, agriculture, food, health, fitness, music to stock markets! Capt. Abraham's core values include honesty, integrity, and compassion instilled through his proud service in the Indian Navy. Now, he seeks to share his wisdom for the betterment of humanity, epitomizing a life dedicated to service and the pursuit of knowledge.",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032096491_0ee9fd1e-e171-42e1-93d9-611fc9bf7608_Untitled%2Bdesign%2B-%2B13.png",
    "socketId": "",
    "categories": [
        {
            "$oid": "66766640712aefd7bc8c1b50"
        },
        {
            "$oid": "6676664f712aefd7bc8c1b51"
        },
        {
            "$oid": "667664ebbafddf42c11b4a14"
        },
        {
            "$oid": "6639cc43f742b02413a50524"
        },
        {
            "$oid": "6639cc43f742b02413a5051c"
        },
        {
            "$oid": "6639cc43f742b02413a50525"
        }
    ],
    "languages": [
        "English, Malayalam"
    ],
    "profileCompleted": true,
    "score": 3.9,
    "repeat_score": 0,
    "total_score": 26,
    "calls_share": 0,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/abraham.mp4",
    "closingGreeting": 6.08,
    "flow": 10.05,
    "probability": 16.36,
    "timeSpent": 11.19,
    "timeSplit": 13.18,
    "tonality": 9.06,
    "userSentiment": 18.08,
    "openingGreeting": 5.5,
    "unlisted": false,
    "type": "saarthi",
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    }
},
{
    "_id": {
        "$oid": "66046b6142f04a057fa2107a"
    },
    "name": "Sarathi Vidya",
    "phoneNumber": "7738725252",
    "otp": "",
    "expiresOtp": "2024-07-22T02:32:45.444Z",
    "active": true,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Music | Movies | Philosophy",
    "status": "online",
    "isBusy": false,
    "__v": 3,
    "description": "Meet Sarathi Vidya from Maharashtra. A HR and Talent Acquisition veteran ,Vidya is a powerhouse with a zest for life! Hailing from Boisar and now rocking it in Mumbai, she's a street food connoisseur and a karaoke queen.  Vidya's mantra? Live and let live, baby! She's all about karma and spreading positivity. A fighter and a self-made success, she's also a social sector superhero, always ready to lend a helping hand. Whether she's belting out tunes or making a difference in the world, Vidya is one unstoppable force of awesomeness!",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032941733_7fc44d18-9795-4b8b-b289-3b365e7e8891_Untitled%2Bdesign%2B-%2B2.png",
    "socketId": "",
    "languages": [
        "English, Hindi, Marathi"
    ],
    "profileCompleted": true,
    "score": 4.1,
    "repeat_score": 0,
    "total_score": 27,
    "categories": [
        {
            "$oid": "667692c4994dbe114898bfc6"
        },
        {
            "$oid": "66769249994dbe114898bfc5"
        },
        {
            "$oid": "6639cc43f742b02413a5051b"
        },
        {
            "$oid": "6639cc43f742b02413a50519"
        },
        {
            "$oid": "6639cc43f742b02413a5050b"
        },
        {
            "$oid": "6639cc43f742b02413a5051c"
        }
    ],
    "calls_share": 0,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/vidya.mp4",
    "closingGreeting": 6.07,
    "flow": 10.5,
    "probability": 16.91,
    "timeSpent": 11.83,
    "timeSplit": 13.75,
    "tonality": 9.19,
    "userSentiment": 18.41,
    "openingGreeting": 6.28,
    "unlisted": false,
    "type": "saarthi",
    "lastModifiedBy": {
        "$oid": "665b5b1610b36290eaa59d26"
    }
},
{
    "_id": {
        "$oid": "66046d7b42f04a057fa210c4"
    },
    "name": "Sarathi Karunakar",
    "phoneNumber": "9762007100",
    "otp": "",
    "expiresOtp": "2024-07-21T09:47:46.246Z",
    "active": true,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Sports | Travel | Academics",
    "status": "offline",
    "isBusy": false,
    "__v": 2,
    "description": "Meet Sarathi Karunakar from Uttarakhand. The dynamic marketer-turned-academician, Karuankar originally hails from Patna but now rocks it in Dehradun with his wifeand two sons. With a knack for entrepreneurship, he has launched multiple companies in 30 years of career - including sports and e-commerce platforms. When he's not busy mentoring or teaching, you'll find him cheering on his favorite football and cricket teams or grooving to some tunes. Karunakar's love for food, sports and travel knows no bounds, and he's always ready to lend a helping hand to the underprivileged. With a career as colorful as his personality, he's on a mission to spread happiness and make a positive impact wherever he goes.",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032301549_94ec1f30-80b8-4fe2-b04e-b0c9579db2b5_Untitled%2Bdesign%2B-%2B9.png",
    "socketId": "",
    "categories": [
        {
            "$oid": "6639cc43f742b02413a5052e"
        },
        {
            "$oid": "6639cc43f742b02413a5050d"
        },
        {
            "$oid": "6639cc43f742b02413a50512"
        },
        {
            "$oid": "6676721ba1fbbf4c78790853"
        },
        {
            "$oid": "66767225bafddf42c11b4a17"
        },
        {
            "$oid": "6639cc43f742b02413a5050f"
        }
    ],
    "languages": [
        "English, Hindi, Marathi"
    ],
    "profileCompleted": true,
    "score": 4.2,
    "repeat_score": 0,
    "total_score": 28,
    "calls_share": 0,
    "closingGreeting": 6.07,
    "flow": 10.22,
    "probability": 17.21,
    "timeSpent": 11.5,
    "timeSplit": 13.3,
    "tonality": 9.5,
    "userSentiment": 18.51,
    "openingGreeting": 6.02,
    "unlisted": false,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/karunakar.MOV",
    "type": "saarthi",
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    }
},
{
    "_id": {
        "$oid": "66046e3942f04a057fa210da"
    },
    "name": "Sarathi Anubha",
    "phoneNumber": "9819691161",
    "otp": "",
    "expiresOtp": "2024-07-18T09:28:16.778Z",
    "active": true,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Counselling | Meditation | Yoga",
    "status": "offline",
    "isBusy": false,
    "__v": 3,
    "description": "Meet Sarathi Anubha from Maharashtra. A pillar of integrity, Anubha boasts of a distinguished career that spans across teaching to Senior HR professional in India's top firm. Born in Moradabad, she now thrives in Mumbai with her husband and well settled children. You can talk to her about diverse interests include traveling, counseling, arts, reading gardening, mental health which reflect her vibrant spirit. An advocate for love and empathy, she seeks to serve humanity by teaching underprivileged children and aiding the needy. Anubha stands apart as a shining example of compassion and dedication to making a positive impact in the world.",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032181164_73e83bdb-9d55-4008-aa21-10d8aca03f11_Untitled%2Bdesign%2B-%2B7.png",
    "socketId": "",
    "categories": [
        {
            "$oid": "66768eeb87dff894878ae587"
        },
        {
            "$oid": "66768f14994dbe114898bfc3"
        }
    ],
    "languages": [
        "English,Hindi"
    ],
    "profileCompleted": true,
    "score": 4.2,
    "repeat_score": 0,
    "total_score": 28,
    "calls_share": 0,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/anubha.mp4",
    "closingGreeting": 5.9,
    "flow": 10.22,
    "probability": 17.63,
    "timeSpent": 11.41,
    "timeSplit": 12.84,
    "tonality": 9.48,
    "userSentiment": 18.37,
    "openingGreeting": 5.85,
    "unlisted": false,
    "type": "expert",
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    }
},
{
    "_id": {
        "$oid": "66046eca42f04a057fa21105"
    },
    "name": "Sarathi Ashok",
    "phoneNumber": "9891498898",
    "otp": "",
    "expiresOtp": "2024-07-17T13:26:05.394Z",
    "active": true,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Dance | Cooking | Psychology",
    "status": "offline",
    "isBusy": false,
    "__v": 2,
    "description": "Meet Sarathi Ashok from Delhi .With over 3 decades spent by him serving private sector and UN, and having delivered more than 100 development projects successfully, many of which are part of India story, Ashok feels blessed to have worked under UN peacekeeping mission and a country with conflict. Beyond his commerce and business acumen, he's a singer and a dancer who loves mashups. Known for his laid-back charm and zest for life, Ashok leaves a lasting impact wherever he goes. With a heart for helping others and a mind for innovation, he continues to love learning new things and always up for life's adventures. Wouldn’t conversing with a seasoned Sarathi like Ashok be fascinating ?",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032264785_ff1f808e-64ca-4846-afc3-b7e4f375d103_Untitled%2Bdesign%2B-%2B5.png",
    "socketId": "",
    "categories": [
        {
            "$oid": "66766f5abafddf42c11b4a16"
        },
        {
            "$oid": "6639cc43f742b02413a5051c"
        },
        {
            "$oid": "6639cc43f742b02413a50523"
        },
        {
            "$oid": "6639cc43f742b02413a5050c"
        },
        {
            "$oid": "66768fed994dbe114898bfc4"
        },
        {
            "$oid": "6639cc43f742b02413a50524"
        }
    ],
    "languages": [
        "English, Hindi, Punjabi"
    ],
    "profileCompleted": true,
    "score": 4,
    "repeat_score": 0,
    "total_score": 26,
    "calls_share": 0,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/ashok.mp4",
    "closingGreeting": 5.84,
    "flow": 10.18,
    "probability": 17.63,
    "timeSpent": 11.01,
    "timeSplit": 12.62,
    "tonality": 9.25,
    "userSentiment": 18.11,
    "openingGreeting": 5.68,
    "unlisted": false,
    "type": "saarthi",
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    }
},
{
    "_id": {
        "$oid": "66046f6342f04a057fa21121"
    },
    "name": "Sarathi Shrikant",
    "phoneNumber": "9960105902",
    "otp": "",
    "expiresOtp": "2024-07-21T16:11:23.748Z",
    "active": true,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Astrology | Spirituality | Finance",
    "status": "offline",
    "isBusy": false,
    "__v": 3,
    "description": "Meet Sarathi Shrikant from Nashik, Maharahtra. A seasoned insurance professional and an affectionate financial guide.  He is a proud father of son who has been felicitated by the Prime Minister of India. He is a student for life. Shrikant has a diverse career spanning law, commerce, insurance and finance. Retirement hasn't slowed him down- he teaches, actively learns about new computer technologies, dabbles in astrology and volunteers for causes close to his heart.  You can talk to him about finance, life insurance, corporate experiences, law, life in general, spirituality, astrology and self development.  An advocate for positivity and good deeds (Karma), Shrikant embodies the essence of a compassionate leader and mentor.",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032683456_faee5cc2-c9bc-465e-9b78-a7c533cf18df_Untitled%2Bdesign%2B-%2B14.png",
    "socketId": "",
    "categories": [
        {
            "$oid": "66769525994dbe114898bfc7"
        },
        {
            "$oid": "66769566994dbe114898bfc9"
        }
    ],
    "languages": [
        "English,Hindi,Marathi"
    ],
    "profileCompleted": true,
    "score": 4,
    "repeat_score": 0,
    "total_score": 26,
    "calls_share": 0,
    "closingGreeting": 5.55,
    "flow": 9.41,
    "probability": 17.61,
    "timeSpent": 11.18,
    "timeSplit": 13.31,
    "tonality": 9.21,
    "userSentiment": 18.24,
    "openingGreeting": 5.49,
    "unlisted": false,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/shrikant.mp4",
    "type": "expert",
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    }
},
{
    "_id": {
        "$oid": "665b0a3b9dc5f51561d4b3db"
    },
    "name": "Sera",
    "phoneNumber": "7348952525",
    "otp": "",
    "expiresOtp": "2024-06-30T06:37:46.749Z",
    "active": false,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Philosophy | Spirituality | Movies",
    "status": "offline",
    "isBusy": false,
    "__v": 3,
    "description": "Test",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1711567086585_8f282518-cc2d-42c4-90ed-959f30d74142_WhatsApp%2BImage%2B2024-03-20%2Bat%2B21.30.37_586437c0.jpg",
    "socketId": "",
    "languages": [
        "English, Hindi, Punjabi"
    ],
    "profileCompleted": true,
    "score": 3.3,
    "repeat_score": 40,
    "total_score": 38,
    "calls_share": 0.79,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/tarvin.mp4",
    "closingGreeting": 6,
    "flow": 9.5,
    "probability": 14.25,
    "timeSpent": 5.75,
    "timeSplit": 9.5,
    "tonality": 0,
    "userSentiment": 14.75,
    "openingGreeting": 4.75,
    "unlisted": false,
    "categories": [
        {
            "$oid": "6639cc43f742b02413a5050f"
        },
        {
            "$oid": "6639cc43f742b02413a50510"
        },
        {
            "$oid": "6639cc43f742b02413a50511"
        },
        {
            "$oid": "6639cc43f742b02413a50512"
        },
        {
            "$oid": "6639cc43f742b02413a50513"
        }
    ],
    "type": "saarthi",
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    }
},
{
    "_id": {
        "$oid": "665ee53def29f5b2e07b1a80"
    },
    "name": "Sarathi Sushma",
    "proflieCompleted": false,
    "categories": [
        {
            "$oid": "6639cc43f742b02413a5052e"
        },
        {
            "$oid": "66769170bcc12d0a6c13fc7f"
        },
        {
            "$oid": "6676978abcc12d0a6c13fc80"
        },
        {
            "$oid": "6676980b994dbe114898bfcd"
        },
        {
            "$oid": "6639cc43f742b02413a5050f"
        },
        {
            "$oid": "66767225bafddf42c11b4a17"
        }
    ],
    "description": "Meet  Sarathi Sushma from Mumbai. A compassionate soul born in Kolkata and now residing in Mumbai with beautiful family. A creative textile designer since 1991, Sushma's journey began as a homemaker. Deeply rooted in Buddhist philosophy, she believes in the law of cause and effect, karma, and inclusion without discrimination. Proud of her resilience and unwavering love, she champions support for seniors, especially those facing isolation. Sushma strives to create inclusive community programs, ensuring dignity and a fulfilling life for the elderly.",
    "languages": [
        "English,Hindi,Bengali"
    ],
    "phoneNumber": "9820556541",
    "topics": "Spirituality,volunteer,Family",
    "expiresOtp": "2024-07-16T08:46:04.810Z",
    "otp": "",
    "__v": 4,
    "active": true,
    "isBusy": false,
    "profileCompleted": true,
    "status": "offline",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032839508_ee64d928-804c-4786-b1cf-3c926bb98044_Untitled%2Bdesign%2B-%2B4.png",
    "type": "saarthi",
    "score": 3.6,
    "closingGreeting": 6.39,
    "flow": 10.9,
    "openingGreeting": 5.94,
    "probability": 15.26,
    "timeSpent": 9.59,
    "timeSplit": 12,
    "tonality": 9,
    "userSentiment": 17.23,
    "repeat_score": 23,
    "calls_share": 3.46,
    "total_score": 35,
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    }
},
{
    "_id": {
        "$oid": "665ee6a5ef29f5b2e07b1a81"
    },
    "name": "Sarathi Meetu",
    "proflieCompleted": true,
    "categories": [
        {
            "$oid": "66767313a1fbbf4c78790854"
        },
        {
            "$oid": "66769994bcc12d0a6c13fc82"
        }
    ],
    "description": "Meet Sarathi Meetu from Delhi. A dedicated lawyer practicing for nearly a decade. Specialized in making Legal Will, Matrimonial disputes, Cheque Bouncing, Property disputes, Criminal complaints and POCSO. Raised in Delhi, she loves to read, travel, meditate, and practice yoga. She values integrity, enthusiasm, honesty, and a good sense of humor and believes in leading her life with authenticity and utmost honesty. She is proud of her strength, resilience, and constant curiosity to learn and invest in self-improvement. She is committed to give back to society by using her expertise to consult and guide on legal aspects of life or in general. Her kindness and empathetic nature help her to connect with people easily and her mission is to guide, help & uplift others in hard times. She is passionate towards welfare of society.",
    "languages": [
        "English,Hindi"
    ],
    "phoneNumber": "9717919662",
    "topics": "Matrimony law,Civil law,Criminal Disputes",
    "expiresOtp": "2024-07-17T10:25:04.505Z",
    "otp": "",
    "__v": 3,
    "active": true,
    "isBusy": false,
    "profileCompleted": true,
    "status": "offline",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032345959_52f606f6-a13c-4eb6-8bc5-f516ed1ed433_Untitled%2Bdesign%2B-%2B12.png",
    "type": "expert",
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    },
    "score": 3.8,
    "closingGreeting": 6.67,
    "flow": 10.67,
    "openingGreeting": 6.33,
    "probability": 17.33,
    "timeSpent": 7,
    "timeSplit": 10,
    "tonality": 9.5,
    "userSentiment": 17.67,
    "repeat_score": 100,
    "calls_share": 0.31,
    "total_score": 65
},
{
    "_id": {
        "$oid": "665ee7f7a42425fb02672532"
    },
    "name": "Sarathi Siddhartha",
    "categories": [
        {
            "$oid": "66769dc7b19dbdc217daa1a1"
        },
        {
            "$oid": "66769c1987dff894878ae58b"
        }
    ],
    "description": "Meet Sarathi Siddhartha from Lucknow.A  retired General Manager who splits his time between the serene hills of Almora and the vibrant city of Lucknow. A golf enthusiast and fitness devotee, he also finds solace in spirituality and enjoys meeting new people and reading. Siddhartha upholds values of honesty, trust, integrity, love, sincerity, and gratitude. He is most proud of his happy, supportive family and cherished friends. Committed to giving back, Siddhartha dedicates his time to helping the needy, teaching underprivileged children, and spreading happiness. His life is a testament to the power of kindness, integrity, and community.",
    "languages": " English,Hindi",
    "phoneNumber": "8979653377",
    "topics": "Pranic Healing, Mindfulness , Spirituality.",
    "expiresOtp": "2024-06-21T05:05:11.349Z",
    "otp": "",
    "__v": 2,
    "active": false,
    "isBusy": false,
    "profileCompleted": true,
    "status": "offline",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032721994_369eb3dc-9120-4a18-aac3-be4bbb02e073_Untitled%2Bdesign%2B-%2B3.png",
    "type": "expert",
    "score": 4,
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    },
    "closingGreeting": 0,
    "flow": 0,
    "openingGreeting": 0,
    "probability": 0,
    "timeSpent": 0,
    "timeSplit": 0,
    "tonality": 0,
    "userSentiment": 0
},
{
    "_id": {
        "$oid": "665f08755b0ecb4e050c2ae9"
    },
    "name": "Sarathi Anupma",
    "proflieCompleted": false,
    "categories": [
        {
            "$oid": "66769aa1994dbe114898bfce"
        },
        {
            "$oid": "66769ab3bcc12d0a6c13fc83"
        }
    ],
    "description": "Meet Sarathi  Anupma from Gaziabad. A healer  with 35 years of experience in  Reiki healing.Born in Firozepur, Punjab, she has a deep love for music and constantly seeks new ways to stay aware and innovative. Guided by the support of her Siddhaguru, Anupma feels blessed and protected. Proud of her son, she strives to spread positivity with a pure heart and a positive mindset. With a passion for skin therapy and cooking, she believes in the power of meditation and understanding others with love, respect, and patience. Anupma also leads an 'OM' chanting and meditation group, cherishing every moment.",
    "languages": [
        "English,Hindi,Punjabi"
    ],
    "phoneNumber": "9999297990",
    "topics": "Reiki Healing,Distance healing",
    "expiresOtp": "2024-07-17T05:01:35.822Z",
    "otp": "",
    "__v": 3,
    "active": true,
    "isBusy": false,
    "profileCompleted": true,
    "status": "offline",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032217676_5b2b6aea-93a2-4115-8af9-45c0c9082a53_Untitled%2Bdesign%2B-%2B11.png",
    "type": "expert",
    "score": 3.9,
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    },
    "closingGreeting": 7.53,
    "flow": 11.57,
    "openingGreeting": 6.74,
    "probability": 16.94,
    "timeSpent": 8.84,
    "timeSplit": 11.12,
    "tonality": 9,
    "userSentiment": 17.95,
    "repeat_score": 80,
    "calls_share": 1.26,
    "total_score": 54
},
{
    "_id": {
        "$oid": "668b59bc0dd1d65ce410b563"
    },
    "name": "Sarathi Abhishek",
    "categories": [
        {
            "$oid": "668b5b850dd1d65ce410b564"
        },
        {
            "$oid": "668946201815b7bb9ff25bac"
        }
    ],
    "proflieCompleted": true,
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    },
    "phoneNumber": "7355458002",
    "score": 3.8,
    "topics": "Astrology",
    "status": "offline",
    "description": "Meet Abhishek from Lucknow. A distinguished individual with a master's degree in IT Management, and an M.A. in Astrology from a reputed Indian university.  Abhishek is known for his honesty, humility, and deep empathy. His expertise spans Astrology, Palmistry, Vastu Shastra, Yantra, Rudraksha Therapy, Gem Therapy, and Tantra. He offers reliable astrological services, addressing issues related to marriage, education, legal problems, relationships, and health. Abhishek believes in the profound impact of karma and destiny, guiding others to understand their actions and align with their deeper beliefs for a fulfilling life.",
    "languages": [
        "Hindi"
    ],
    "expiresOtp": "2024-07-21T08:11:17.083Z",
    "otp": "",
    "__v": 1,
    "active": true,
    "isBusy": false,
    "profileCompleted": true,
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1720409879834_9469c4a2-5604-457d-80c6-84cb3dc52886_1000038318.jpg",
    "type": "expert",
    "closingGreeting": 7,
    "flow": 10.75,
    "openingGreeting": 5.5,
    "probability": 15.25,
    "timeSpent": 9.25,
    "timeSplit": 11,
    "tonality": 0,
    "userSentiment": 18,
    "repeat_score": 66,
    "calls_share": 0.63,
    "total_score": 45
},
{
    "_id": {
        "$oid": "66920805f1148d92190a28bc"
    },
    "name": "Test",
    "categories": [
        {
            "$oid": "660e18963a2d66e7354b5adc"
        }
    ],
    "proflieCompleted": true,
    "lastModifiedBy": {
        "$oid": "668be3fda130674c57819937"
    },
    "phoneNumber": "9398036558",
    "score": 3.1,
    "topics": "Astrology",
    "status": "offline",
    "description": "Test Expert.",
    "languages": [
        "Hindi"
    ],
    "expiresOtp": "2024-07-21T12:24:31.023Z",
    "otp": "",
    "__v": 1,
    "active": false,
    "isBusy": false,
    "profileCompleted": true,
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1720846387323_ffd4acd4-edbf-4e53-8666-6052bcce263d_Lone_wallpaper.jpg",
    "type": "expert",
    "closingGreeting": 2.5,
    "flow": 6,
    "openingGreeting": 2,
    "probability": 0,
    "timeSpent": 10,
    "timeSplit": 0,
    "tonality": 0,
    "userSentiment": 16.5,
    "repeat_score": 100,
    "calls_share": 0.32,
    "total_score": 54
},
{
    "_id": {
        "$oid": "mayanksGame"
    },
    "name": "Sarathi Mayank",
    "proflieCompleted": false,
    "categories": [
        {
            "$oid": "66769aa1994dbe114898bfce"
        },
        {
            "$oid": "66769ab3bcc12d0a6c13fc83"
        }
    ],
    "description": "Meet Sarathi  Anupma from Gaziabad. A healer  with 35 years of experience in  Reiki healing.Born in Firozepur, Punjab, she has a deep love for music and constantly seeks new ways to stay aware and innovative. Guided by the support of her Siddhaguru, Anupma feels blessed and protected. Proud of her son, she strives to spread positivity with a pure heart and a positive mindset. With a passion for skin therapy and cooking, she believes in the power of meditation and understanding others with love, respect, and patience. Anupma also leads an 'OM' chanting and meditation group, cherishing every moment.",
    "languages": [
        "English,Hindi,Punjabi"
    ],
    "phoneNumber": "9936142128",
    "topics": "Reiki Healing,Distance healing",
    "expiresOtp": "2024-07-17T05:01:35.822Z",
    "otp": "",
    "__v": 3,
    "active": true,
    "isBusy": false,
    "profileCompleted": true,
    "status": "offline",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1719032217676_5b2b6aea-93a2-4115-8af9-45c0c9082a53_Untitled%2Bdesign%2B-%2B11.png",
    "type": "expert",
    "score": 3.9,
    "lastModifiedBy": {
        "$oid": "665b5b5310b36290eaa59d27"
    },
    "closingGreeting": 7.53,
    "flow": 11.57,
    "openingGreeting": 6.74,
    "probability": 16.94,
    "timeSpent": 8.84,
    "timeSplit": 11.12,
    "tonality": 9,
    "userSentiment": 17.95,
    "repeat_score": 80,
    "calls_share": 1.26,
    "total_score": 54
},
{
    "_id": {
        "$oid": "665b0a3b9dc5f51561d4b3db"
    },
    "name": "Sera",
    "phoneNumber": "9936142128",
    "otp": "",
    "expiresOtp": "2024-08-14T10:05:49.705Z",
    "active": false,
    "createdDate": {
        "$date": "2024-03-27T16:16:05.173Z"
    },
    "topics": "Philosophy | Spirituality | Movies",
    "status": "online",
    "isBusy": false,
    "__v": 3,
    "description": "Test",
    "profile": "https://sukoon-media.s3.ap-south-1.amazonaws.com/1711567086585_8f282518-cc2d-42c4-90ed-959f30d74142_WhatsApp%2BImage%2B2024-03-20%2Bat%2B21.30.37_586437c0.jpg",
    "socketId": "",
    "languages": [
        "English, Hindi, Punjabi"
    ],
    "profileCompleted": true,
    "score": 3.3,
    "repeat_score": 40,
    "total_score": 38,
    "calls_share": 0.79,
    "video": "https://sukoon-media.s3.ap-south-1.amazonaws.com/sarthi-video/tarvin.mp4",
    "closingGreeting": 6,
    "flow": 9.5,
    "probability": 14.25,
    "timeSpent": 5.75,
    "timeSplit": 9.5,
    "tonality": 0,
    "userSentiment": 14.75,
    "openingGreeting": 4.75,
    "unlisted": false,
    "categories": [
        {
            "$oid": "6639cc43f742b02413a5050f"
        },
        {
            "$oid": "6639cc43f742b02413a50510"
        },
        {
            "$oid": "6639cc43f742b02413a50511"
        },
        {
            "$oid": "6639cc43f742b02413a50512"
        },
        {
            "$oid": "6639cc43f742b02413a50513"
        }
    ],
    "type": "saarthi",
    "lastModifiedBy": {
        "$oid": "668e67c3c4ef5b0f57269638"
    },
    "displayScore": "4.54",
    "isGamesPlay": false,
    "fcmToken": "cv08JehvRoeURNR-0mO7uJ:APA91bHPBF1Ftm7Vk7tRK8jjT4wj4epAcd-cd4IKmXSQ9Bs-ybfjz4JQVzcv7ffuZndxPsy5QLZGFn3blVq2k1y4CiksnUSdDeGTD6LX58hDTY95KNyN9w4dwf8FvhgEto2CKM-6x_H5"
}
]



export const filterSarathi = (id: string) => {

    return sarathiList.filter(ele => ele._id.$oid == id)[0] || []
}
