import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const BACKEND_URL = 'https://prod-backend.sukoonunlimited.com/api/expert';

interface CustomerPersona {
    gender: ValueDetail;
    ethnicity: ValueDetail;
    education: ValueDetail;
    marital_status: ValueDetail;
    income: ValueDetail;
    living_status: ValueDetail;
    medical_history: ValueDetail;
    locationcity: ValueDetail;
    comfort_with_technology: ValueDetail;
    standard_of_living: ValueDetail;
    family_members: ValueDetail;
    work_status: ValueDetail;
    last_company_worked_for: ValueDetail;
    language_preference: ValueDetail;
    physical_state_of_being: ValueDetail;
    needs: ValueDetail;
    values: ValueDetail;
    pain_points_challenges: ValueDetail;
    motivators: ValueDetail;
  }
  
  interface ValueDetail {
    value: string;
  }

export interface GameSession {
    _id?: string;
    userPersona?: CustomerPersona,
    sarathiID?: {
        _id?: string;
        name?: string;
        description?: string;
        languages?: string[];
        profile?: string;
    };
    userId?: {
        _id?: string;
        isPaidUser?: boolean;
        name?: string;
        birthDate?:string;
        city?:string
    };
    category?: string;
    level?: string;
    gameuuid?: string;
    gameLink?: string;
    createdAt?: string;
    updatedAt?: string;
}


export const fetchGamesDetails = createApi({
    reducerPath: 'shortsListApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BACKEND_URL
    }),
    tagTypes: ["fetchGamesDetails"],
    endpoints: (builder) => ({
        fetchGamesDetails: builder.query({
            query: (gameId: string) => ({
                url: `getGamesById?gameId=${gameId}`,
                method: 'GET',

            }),
            keepUnusedDataFor: 300,
            transformResponse: (response: GameSession) => {
                return response
            }
        })
    })
})

export const { useFetchGamesDetailsQuery, useLazyFetchGamesDetailsQuery } = fetchGamesDetails;