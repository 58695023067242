import React from 'react';
import RtcClient from './rtc-client';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Button from '../../Button';
import { updateSarathiStatus } from '../../../utils/axiosHelper';
import { Info } from '@mui/icons-material';
import UserInformationButton from '../../UserInfoButton';


interface IProps {
  onRef: (ref: any) => void;
  config: any;
  sarathiId?: string;
  streamOptions: any;
  handleUserPublishStream: any;
  handleUserUnpublishStream: any;
  // handleUserStartVideoCapture: any;
  // handleUserStopVideoCapture: any;
  handleUserJoin: any;
  handleUserLeave: any;
  handleAutoPlayFail: any;
  handleEventError: any;
  handlePlayerEvent: any;
}

class RtcComponent extends React.Component<IProps, any> {
  rtc: RtcClient;
  constructor(props: IProps) {
    super(props);

    this.rtc = new RtcClient(props);
  }
  state = {
    isMute: false
  }
  componentDidMount() {
    this.props.onRef(this.rtc);
  }

  mute = () => {
    this.rtc.changeAudioState(this.state.isMute)
    this.setState({ isMute: !this.state.isMute })
  }

  handleUserJoin = (res:any) => {
    console.log("FLFLFLLFLF",res)
  }
  handleExitClick = () => {
    if (this.props.sarathiId) updateSarathiStatus(this.props.sarathiId, false)
    window.location.href = 'https://www.sukoonunlimited.com';
  }
  render() {
    return <>
      <div className="absolute bottom-0 right-0 w-full  flex flex-row shadow-top h-auto md:mt-6 px-5 md:px-10 bg-orange py-1 items-center rounded-lg  sm:flex-row sm:justify-center">
        <div className='justify-between sm:justify-center w-full flex flex-row gap-1 md:gap-10'>
          <button onClick={this.mute} className='flex flex-row items-center text-left gap-2 md:gap-5  h-full p-3'>
            {this.state.isMute ? <MicOffIcon className='w-16 h-16   bg-red-500 text-bgColor rounded-full' /> : <MicIcon className='w-16 h-16   bg-tealDark text-bgColor rounded-full' />}
            <h1>{this.state.isMute ? 'Unmute' : 'Mute Mic'}</h1>
          </button>
          <UserInformationButton />
          <Button onClick={this.handleExitClick} className='bg-red-600 font-heavyFont text-white rounded-lg hover:bg-red-600 transition w-auto pt-2 pb-2 pr-4 pl-4 h-fit '>Exit</Button>

        </div>


      </div>
    </>;
  }
}
//@ts-ignore
export default RtcComponent