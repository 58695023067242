// import { axiosInstance } from "@/utils/axiosHelper";
import { axiosInstance } from "../../utils/axiosHelper";
import { OutputResponse } from "./interface";

export const getQuestions = async (level: number = 1, user_id: string, category: string = "gk") => {
  try {
    const res = await axiosInstance.post<OutputResponse>(
      "https://6x4j0qxbmk.execute-api.ap-south-1.amazonaws.com/main/actions/quiz_game",
      {
        user_id: user_id,
        level: level,
        question_to_show: 10,
        category
      }
    );
    return res;
  } catch (error) {
    console.log(error, "get questions error");
  }
};
