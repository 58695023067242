import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface gamesConfig {
    expertId: string,
    playerId: string,
    gameLevel: string,
    gameId: string,
    totalQuestion?: string,
    isUserJoined: boolean,
    isSarathiJoined: boolean,
    gameType?: string,
    minLevel?: number,
    maxLevel?: number

}

const initialState: gamesConfig = {
    expertId: "",
    playerId: "",
    gameId: "",
    gameLevel: "",
    totalQuestion: "",
    isUserJoined: false,
    isSarathiJoined: false
};


export const gamesConfigSlice = createSlice({
    name: "gamesConfigSlice",
    initialState,
    reducers: {
        setGamesConfig: (state, action: PayloadAction<gamesConfig>) => {
            return {
                ...action.payload
            }
        },
        setGameApiFetch: (state, action: PayloadAction<gamesConfig>) => {
            return {
                ...state, gameType: action.payload.gameType,
                gameLevel: action.payload.gameLevel,
                minLevel: action.payload.minLevel,
                maxLevel: action.payload.minLevel,
            }
        }
    }
})

export const { setGamesConfig, setGameApiFetch } = gamesConfigSlice.actions;
export default gamesConfigSlice.reducer;