import { useState, useEffect } from 'react';
import { ref, onValue, update, get, remove } from 'firebase/database';
import { database } from '../utils/firebaseConfig';
// import { database } from '@/utils/firebaseConfig';

// Utility function to perform a deep merge of two objects
const deepMerge = (target: any, source: any) => {
    for (const key in source) {
        if (source[key] instanceof Object && key in target) {
            deepMerge(target[key], source[key]);
        } else {
            target[key] = source[key];
        }
    }
    return target;
};

export const useRealtimeDatabase = (path: string) => {
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const dbRef = ref(database, path);

        const unsubscribe = onValue(dbRef, (snapshot) => {
            const newData = snapshot.val();
            setData(newData);
        });

        // Cleanup listener on unmount
        return () => {
            unsubscribe();
        };
    }, [path]);

    const updateData = async (userID: string, newData: any) => {
        try {
            const userRef = ref(database, `${path}/${userID}`);
            const snapshot = await get(userRef);
            const existingData = snapshot.val() || {};

            // Perform a deep merge of existing data and new data
            const updatedData = deepMerge({ ...existingData }, newData);
            await update(userRef, updatedData);
            return updatedData;
        } catch (error) {
            console.error('Error updating data:', error);
            //@ts-ignore
            throw new Error(`Error updating data for user ${userID}: ${error.message}`);
        }
    };

    const removeData = async (userID: string, newData: any) => {
        try {
            const userRef = ref(database, `${path}/${userID}`);
           
            await update(userRef, newData);
         
            return newData;
        } catch (error) {
            console.error('Error updating data:', error);
            //@ts-ignore
            throw new Error(`Error updating data for user ${userID}: ${error.message}`);
        }
    };

    return { data, updateData,removeData };
};
