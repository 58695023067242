import BytePlusRTC, { IRTCEngine } from '@byteplus/rtc';
import { RoomProfileType, StreamIndex, MediaType } from '@byteplus/rtc';
import { message } from 'antd';
import axios from 'axios';

// To create an RTC engine instance
const engine: IRTCEngine = BytePlusRTC.createEngine('6696518f721f7801754f4629');

const config = {
  appId: '6696518f721f7801754f4629',
  roomId: '8',
  uid: 'test',
  token: '00166414dcd5d69250174170421NQDvTc4EE1WWZiNjlmYBADgEAHRlc3QFAAAAI2OWZgEAI2OWZgIAI2OWZgMAI2OWZgQAAAAAACAAjPzkwSAbmnHFR6JjbNxABf30uaNgx4zOi8LpOv5bgiY=',
};

/**
 * 
 * @param token | API GENERATED TOKEN
 * @param roomId | roomID
 * @param uid | randomUserID
 */
const join = async (token: string, roomId: string, uid: string) => {
  try {
    await engine.joinRoom(
      token,
      roomId,
      {
        userId: uid,
      },
      {
        isAutoPublish: true,
        isAutoSubscribeAudio: true,
        isAutoSubscribeVideo: false,
        roomProfileType: RoomProfileType.communication,
      },
      
    );
    //@ts-ignore
    engine.on('onUserJoined', async (userId) => {
      console.log('User joined:', userId);
      message.destroy();
      message.success('User Joined');
      //@ts-ignore
      await engine.subscribeStream(userId, MediaType.AUDIO);
    });

    //@ts-ignore
    engine.on('onAudioFrame', (userId, audioFrame) => {
      console.log('Received audio frame from user:', userId);
      playRemoteAudio(audioFrame);
    });

    // engine.setRemoteVideoPlayer(StreamIndex.STREAM_INDEX_MAIN, {
    //   userId:uid,
    //   renderDom:'remote-div',
    // })

  } catch (e) {
    message.destroy();
    message.error('Failed to Joined');
    console.error('Failed to join the room:', (e as {
      code: typeof BytePlusRTC.ErrorCode;
      message: string;
    }).message);
  }
};

const leaveRoom = () => {
  return engine.leaveRoom();
};

const createLocalStream = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: { echoCancellation: true, noiseSuppression: true },
    });

    const audioTrack = stream.getAudioTracks();
    console.log(audioTrack, "Audio Track");
    
    const audioElement = document.getElementById('audioElement') as HTMLAudioElement;
    audioElement.srcObject = stream;
    audioElement.play();
    
    await engine.setAudioCaptureDevice(audioTrack[0].label);

    // Voice Activity Detection (VAD) to mute the output when speaking
    const audioContext = new (window.AudioContext || window.AudioContext)();
    const source = audioContext.createMediaStreamSource(stream);
    const gainNode = audioContext.createGain();
    const analyser = audioContext.createAnalyser();

    source.connect(analyser);
    analyser.connect(gainNode);
    gainNode.connect(audioContext.destination);

    const dataArray = new Uint8Array(analyser.frequencyBinCount);

    const checkAudioLevel = () => {
      analyser.getByteFrequencyData(dataArray);

      const sum = dataArray.reduce((a, b) => a + b);
      const average = sum / dataArray.length;

      // Define a threshold for your voice level
      const threshold = 50; // Adjust based on environment and testing

      if (average > threshold) {
        gainNode.gain.value = 0; // Mute output
      } else {
        gainNode.gain.value = 1; // Unmute output
      }

      requestAnimationFrame(checkAudioLevel);
    };

    checkAudioLevel();

    // Start audio capture
    await engine.startAudioCapture();
    // engine.setLocalVideoPlayer(StreamIndex.STREAM_INDEX_MAIN, {
    //   renderDom: 'local-player',
    // });

   
    // Publish the audio stream
    await engine.publishStream(MediaType.AUDIO);

  } catch (error) {
    console.error("Error accessing media devices:", error);
  }
};

const playRemoteAudio = (audioFrame: any) => {
  const audioContext = new (window.AudioContext || window.AudioContext)();
  const source = audioContext.createBufferSource();
  const buffer = audioContext.createBuffer(
    1,
    audioFrame.samples.length,
    audioFrame.sampleRate
  );

  buffer.copyToChannel(audioFrame.samples, 0);
  source.buffer = buffer;
  source.connect(audioContext.destination);
  source.start();
};

const generateToken = async (room_id: string, user_id: string) => {
  return axios({
    method: "POST",
    baseURL: "https://6x4j0qxbmk.execute-api.ap-south-1.amazonaws.com/main/actions",
    url: "byte_plus_token",
    data: {
      room_id, user_id
    }
  }).then(response => response.data)
}

export { join, createLocalStream, generateToken, leaveRoom };
