import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { generateToken } from '../../utils/webrtc';
import Meeting from '../../components/AudioChat/pages/Meeting';
import config from '../../components/AudioChat/config';
import { GameSession, useLazyFetchGamesDetailsQuery } from '../../store/api/fetchGameDetail.slice';

interface iProps {
    sarathiId?: string
    GameData: GameSession
}
const MeetingComponent: React.FC<iProps> = ({ GameData, sarathiId }) => {

    const [tokenState, setTokenState] = useState<any[]>([]);
    const location = useLocation();
    // Extract query parameters
    const searchParams = new URLSearchParams(location.search);
    const userType = searchParams.get('type');
    return (
        <>
            {userType === 'sarathi' && GameData?.sarathiID?._id  && GameData._id && (
                <Meeting
                    appId='6696518f721f7801754f4629'
                    gameData={GameData}
                    config={{ ...config, tokens: tokenState }}
                    roomId={GameData._id}
                    sarathiId={GameData?.sarathiID?._id}
                    userId={GameData?.sarathiID?._id}
                    key={'meeting'}
                />
            )}
            {userType === 'user' && GameData && GameData?.sarathiID?._id && GameData?.userId?._id && GameData._id && (
                <Meeting
                    appId='6696518f721f7801754f4629'
                    gameData={GameData}
                    config={{ ...config, tokens: tokenState }}
                    roomId={GameData._id}
                    sarathiId={GameData?.sarathiID?._id}
                    userId={GameData?.userId?._id}
                    key={'meeting--magic'}
                />
            )}
        </>
    );
};

export default MeetingComponent;
