import React, { useState, useEffect } from 'react';
import { createGame, updateSarathiStatus } from '../../utils/axiosHelper';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';
import logo from '../../assets/logo.png'; // Adjust the path as necessary
import ProgressBar from '../../components/ProgressBar';
import { GameSession, useLazyFetchGamesDetailsQuery } from '../../store/api/fetchGameDetail.slice';
import MeetingComponent from '../../components/MeetingComponent';

const ideasAndTrivia = [
  { type: 'Fun Fact', content: "Did you know? The shortest war in history lasted 38 minutes!" },
  { type: 'Trivia', content: "Trivia: Honey never spoils. Archaeologists have found pots of honey in ancient Egyptian tombs that are over 3,000 years old and still edible." },
  { type: 'Idea', content: "While you wait, think about your favorite childhood memory!" },
  { type: 'Fun Fact', content: "Fun Fact: Bananas are berries, but strawberries aren’t!" },
  { type: 'Tip', content: "Quick Tip: Stay hydrated! Drinking water can help keep you focused." },
  { type: 'Fun Fact', content: "Did you know? An octopus has three hearts." },
];

interface WaitingProps {
  isSarathiJoined?: boolean;
  expertName?: string;
  expertImage?: string;
  expertId?:string;
  gameData?:GameSession;
  expertDescription?: string;
  onStartGame: () => void; // Function to handle starting the game
  startGame: () => void;
}

const WaitingScreen: React.FC<WaitingProps> = ({ isSarathiJoined, onStartGame,expertName,expertImage,expertDescription,startGame,expertId,gameData }) => {
  const [seconds, setSeconds] = useState(100);
  const [currentIdeaIndex, setCurrentIdeaIndex] = useState(0);
  const [showPermissionPopup, setShowPermissionPopup] = useState(false);
  const [timerFinished, setTimerFinished] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let userName = searchParams.get('userName');
  let userType = searchParams.get('type');
  const gameUuid = searchParams.get('uuid');
  const level = searchParams.get('level') || '1';
  const adminId = '66a78a14b84484ff9095f7b2';

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => (prev > 0 ? prev - 1 : prev)); // Keep it from going below 0
      if (seconds === 15 && level == '1' && userType === 'user') {
        const gameLink = `https://play.sukoonunlimited.com/${location.search.replace("type=user", "type=sarathi")}`;
        // createGame(adminId, userName || '', gameLink.toString().replace(expertId, adminId)).then(res => {
        //   message.success("Notification Sent to Sarathi, Please wait for 2 Minutes he will be joining Soon ");
        // }).catch(e => {
        //   // Handle error
        // });
      }
    }, 1000);

    if (seconds === 0) {
      setTimerFinished(true); // Set timer finished state
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [seconds]);

  const handlePermissionPopup = () => {
   
    setShowPermissionPopup(false);
    onStartGame(); // Proceed to start the game after 10 seconds
  };

  useEffect(() => {
    const ideaInterval = setInterval(() => {
      if (!isSarathiJoined) {
        setCurrentIdeaIndex((prevIndex) => (prevIndex + 1) % ideasAndTrivia.length);
      }
      // else if(userType == 'sarathi') {
        
      //   updateSarathiStatus(expertId, true);
      // }
    }, 5000); // Change idea every 5 seconds

    return () => clearInterval(ideaInterval);
  }, [isSarathiJoined]);


  useEffect(() => {
    return () => {
      if (expertId && userType == 'sarathi') updateSarathiStatus(expertId, false)
    }
  }, [])

  const currentIdea = ideasAndTrivia[currentIdeaIndex];

  return (
    <div className="flex flex-col h-screen my-auto items-center p-[10%] mx-auto max-w-screen-md">
      {/* Logo */}
        <>
        {expertId && gameData &&  <MeetingComponent GameData={gameData} sarathiId={expertId.toString()} />}
          <div className="relative mb-8">
            {!isSarathiJoined && !timerFinished && (
              <>
              <div className='flex flex-row gap-2 justify-center'>
              <p className="font-normalFont text-2xl text-gray-800">{`Waitng for `}</p>
              <p className="font-normalFont text-2xl text-gray-800">{` ${expertName}`}</p>
              </div>
                
                <div className={`mt-8 font-normal text-3xl text-teal-600 mb-8 transition-transform duration-500`}>
                 <ProgressBar progress={100-seconds} /> 
                </div>
                {/* <div className="mt-16">
                  <p className="text-lg font-normal">While you wait, here’s something:</p>
                  <p className="text-xl font-normalFont text-gray-800 mt-2">{currentIdea.content}</p>
                </div> */}
              </>
            )}
            {timerFinished && !isSarathiJoined && (
              <p className=" font-normalFont  mt-8 mb-8 transition-opacity duration-500 opacity-100">⏰ Sarathi will be joining soon, Please hold on 🎉</p>
            )}
            {isSarathiJoined && (
            <div className='flex flex-col justify-around m-2 p-2   rounded-sm  border-[0.02px] border-black  '>
              <div className='flex flex-row gap-4 items-center shadow-sm p-2 rounded-sm'>
                <img src={expertImage} width={90} height={90} className='rounded-md shadow-lg' />
                <p className="font-normal">{`Say Hello to ${expertName}`}</p>
              </div>
              <div className='flex flex-col gap-4'>
              
                <div className=''>
                  <p className="font-normalFont line-clamp-4">{expertDescription}</p>
                </div>
              </div>
            </div>
            )}

          <div className="mt-4 p-6   border-[1px]  border-YELLOW rounded-lg m-2">
           
            <ul className="list-disc  space-y-2 justify-start text-left">
              <li className='font-normalFont'>{`Adjust the volume of your phone to make sure you can hear  ${expertName}`}</li>
              <li  className='font-normalFont'>Make sure your internet connection is stable.</li>
             {isSarathiJoined && <li  className='font-normalFont'>Can't hear Sarathi? Refresh page</li>}
              <li className='font-normalFont'>
                Still facing challenges? Call Sukoon Assistance at 
                <span className="font-normal"> +91 9110673203</span>.
              </li>
            </ul>
          </div>

          </div>

          {/* Show the button to start the game when Sarathi has joined */}
          {isSarathiJoined && (
          <div  className='fixed bottom-0 p-2 justify-center mb-4'>
            <button
              onClick={startGame}
              className="mt-2 px-2 py-3 text-xl font-normal text-black  bg-YELLOW  rounded-lg shadow-lg  hover:scale-105 hover:shadow-xl active:scale-95"
            >
              Begin Game
            </button>
          </div>
          )}
        </>
      
    </div>
  );
};

export default WaitingScreen;
