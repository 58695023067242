import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: "https://6x4j0qxbmk.execute-api.ap-south-1.amazonaws.com/main/actions",
  timeout: 60 * 1000,
  maxRedirects: 0,
});

export const inviteMember = (phoneNumber: string, sarathi_name: string, game_link: string) => {

  axios({
    baseURL: "https://6x4j0qxbmk.execute-api.ap-south-1.amazonaws.com/main/actions",
    method:"post",
    url: "send_whatsapp",
    data: {
      phone_number: phoneNumber,
      template_name: 'GAMES_TEMPLATE',
      parameters: {
        sarathi_name,
        game_link
      }
    }
  })
}


export function generateRandomFourDigitNumber() {
  return Math.floor(1000 + Math.random() * 9000);
}

// Example usage:
const randomNumber = generateRandomFourDigitNumber();
console.log(randomNumber);