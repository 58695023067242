'use client';

import React from 'react';
// import Button from '@/components/Button';
// import useMediaQuery from '@/hooks/useMediaQuery';
// import SpinWheel from '@/components/Wheel';

import AmazonFinalPopup from '../AmazonFinalPopup';
import useMediaQuery from '../../hooks/useMediaQuery';
import SpinWheel from '../Wheel';
import Button from '../Button';

const AmazonPopup: React.FC = () => {
    const isDesktop = useMediaQuery(768);
    const [showFinalPopup, setShowFinalPopup] = React.useState(false);

    const onOpenClick = () => {
        setShowFinalPopup(true);
    }

    if (showFinalPopup) {
        return <AmazonFinalPopup />;
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="w-[642px] h-[519px] bg-sky-200 rounded-2xl mx-4">
                <div className='mt-6 ml-6'>
                    <img src="/images/carbon-close.svg" alt="Amazon Logo" width={40} height={40} />
                </div>
                <div className='flex flex-col items-center justify-center'>
                    <SpinWheel />
                    <Button
                        onClick={onOpenClick}
                        className='w-[287px] bg-tealDark h-10 mt-5 rounded-xl px-2 text-xl text-white font-heavyFont tracking-wide'>
                        Spin The Wheel
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AmazonPopup;