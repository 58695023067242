"use client";

import React, { ButtonHTMLAttributes } from 'react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: 'primary' | 'secondary' | 'success' | 'danger';
    size?: 'small' | 'medium' | 'large';
    fullWidth?: boolean;
    customClasses?: string;
    onClick?: () => void;
};

const Button: React.FC<ButtonProps> = ({
    variant = 'primary',
    size = 'medium',
    fullWidth = false,
    customClasses = '',
    onClick,
    children,
    ...rest
}) => {
    const buttonClasses = `${variant} ${size} ${fullWidth ? 'full-width' : ''} ${customClasses}`; // Include the custom classes

    return (
        <button className={buttonClasses} onClick={onClick} {...rest}>
            {children}
        </button>
    );
};

export default Button;