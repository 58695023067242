'use client';

import React, { useEffect } from 'react';
// import Button from '@/components/Button';
import useMediaQuery from '../../hooks/useMediaQuery';
import Button from '../Button';
import { useRealtimeDatabase } from '../../hooks/useRealtimeDatabase';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../store/store';
import PlayerSingle from '../Player/PlayerSingle';
// import useMediaQuery from '@/hooks/useMediaQuery';

const ResultsPopup: React.FC<ResultsPopupProps> = ({ player1, player2, context }) => {
    const isDesktop = useMediaQuery(768);

    const { data, updateData,removeData } = useRealtimeDatabase('data');
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const gameUuid = searchParams.get('uuid');
        //@ts-ignore
    let gameLevel: number = searchParams.get('level') ? parseInt(searchParams.get('level')) : 1;
    //@ts-ignore
    const firebaseGameData = data?.[gameUuid][gameLevel];
    
    const sarthiInfo = useAppSelector(data => data.sarathiSlice);
    const isUserWon = parseInt(firebaseGameData?.userScore) > parseInt(firebaseGameData?.sarathiScore) ?  true : false;
    const maxLevel = 3;
    const changeLevel = () => {
        if (gameLevel < maxLevel) {
            searchParams.set('level', (gameLevel + 1).toString());
            navigate(`/quiz?${searchParams.toString()}`);
        } else {
            console.log('Maximum level reached');
        }
    }

    const retryLevelLevel = () => {

        if(gameUuid && gameLevel){
            removeData(gameUuid?.toString(), { [gameLevel]: { restartGame:true  } })
        }
        if (gameLevel < maxLevel) {
            searchParams.set('level', (gameLevel).toString());
          
            navigate(`/?${searchParams.toString()}`);
        } else {
            console.log('Maximum level reached');
        }
    }

    useEffect(()=>{
        if(data && gameUuid && gameLevel) {
            try {
                if(data[gameUuid][gameLevel]['restartGame'] === true) {
                    retryLevelLevel()
                }   
            } catch (error) {
                
            }
        }
    },[JSON.stringify(data)])
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="w-[788px] h-fit relative bg-orange rounded-2xl m-5 overflow-hidden">
                <div className="w-[850px] h-[1200px] -left-10 -top-[940px] rotate-[85deg] absolute origin-center bg-orange rounded-full border-2 border-neutral-200" />
                <div className="left-[110px] top-[45px] absolute text-black text-[28px] font-normal font-pixelFont tracking-wide">Results</div>
                <div className='mt-24 flex flex-col md:flex-row p-5 md:p-10 md:px-20 items-center md:items-start justify-center md:gap-40'>
                    
                    <div className='flex flex-col gap-10 items-center justify-center w-full '>
                        <div className='flex flex-row justify-between w-full'>
                            <PlayerSingle firebaseGameData={firebaseGameData} player1={player1} isUsersTurn={true}  showUserDetail={() => console.log('test')} />
                            <PlayerSingle firebaseGameData={firebaseGameData} player1={player2} isUsersTurn={false} showUserDetail={() => console.log('test')} />
                        </div>
                        
                        <p className='font-normalFont'>{`Winner of Level ${gameLevel}  -  ${firebaseGameData?.userScore >  firebaseGameData?.sarathiScore ? player1.name : player2.name}`}</p>
                       {gameLevel == 3 ? <></>  : <Button onClick={changeLevel} className='bg-tealDark h-10 rounded-xl w-full px-2 text-lg text-white font-heavyFont tracking-wide'>New Level Unlock</Button>}
                        {/* {context === 3 &&
                            <Button className='bg-tealDark h-10 rounded-xl w-full px-2 text-lg text-white font-heavyFont tracking-wide'>Claim Your Reward</Button>
                        } */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultsPopup;
