// @/hooks/useTimer.tsx
import { useState, useEffect } from "react";

export const useTimer = (
  Time: number,
  onTimeUp: () => void,
  shouldReset: boolean,
  shouldStop?: boolean
) => {
  const [time, setTime] = useState(Time);

  useEffect(() => {
    if (time === 0) {
      onTimeUp();
      setTime(Time);
      return;
    }

    if (shouldStop) {
      return;
    }

    const timeout = setTimeout(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [time, onTimeUp, Time, shouldReset, shouldStop]);

  useEffect(() => {
    if (shouldReset) {
      setTime(Time);
    }
  }, [shouldReset]);

  return time;
};
